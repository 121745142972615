import zh from './zh';
import en from './en';
import es from './es';
export const languageLabels = [
    {
        code: zh.code,
        label: zh.label,
        shortName: '🇨🇳',
    },
    {
        code: en.code,
        label: en.label,
        shortName: '🇬🇧',
    },
    {
      code: es.code,
      label: es.label,
      shortName: 'ES',
    },
]
export default {
  default: zh.code,
  zhCN: zh.code,
  enUS: en.code,
  esUS: es.code,
  [ zh.code ]: zh,
  [ en.code ]: en,
  [ es.code ]: es,
};
