import CONST_REQUEST from '../../constants/request';
import * as api from '../../api/system';
import apiTools from '../../utils/store'

const types = {
    ...apiTools.getTypes([
        'sysGroup',
        'colUserList',
        'adminColUserList',
        'userList',
        'roleList',
        'menuListTree',
        'allRoleList',
        'appAllRoleList',
    ]),
}

const getDefaultState = () => {
    return {
        ...apiTools.getStates([
          'sysGroup',
          'colUserList',
          'adminColUserList',
            'userList',
            'roleList',
            'menuListTree',
            'allRoleList',
            'appAllRoleList',
        ]),
    }
}

const getters = {
        ...apiTools.getGetters([
            'sysGroup',
            'colUserList',
            'adminColUserList',
            'userList',
            'roleList',
            'menuListTree',
            'allRoleList',
            'appAllRoleList',
        ])
    }

const buildAction = (apiName) => {
    return ({ commit }, params) => {
        return api[apiName](params).then((res) => {
            if (res.code == CONST_REQUEST.CODE_SUCCESS) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    }
}

const actions = {
        ...apiTools.getActions([
            'getUserList',
            'getColUserList',
           'getAdminColUserList',
            'getRoleList',
            'getMenuListTree',
            'getAllRoleList',
            'getSysGroup',
            'getAppAllRoleList',
        ], { types, api, CONST_REQUEST }),
        addUser: buildAction('addUser'),
        editUser: buildAction('editUser'),
        deleteUser: buildAction('deleteUser'),
        addRole: buildAction('addRole'),
        editRole: buildAction('editRole'),
        getRolePermission: buildAction('getRolePermission'),
        setRolePermission: buildAction('setRolePermission'),
        addMenu: buildAction('addMenu'),
        editMenu: buildAction('editMenu'),
        deleteMenu: buildAction('deleteMenu'),
        addColUser: buildAction('addColUser'),
        editColUser: buildAction('editColUser'),
        addAdminColUser: buildAction('addAdminColUser'),
        editAdminColUser: buildAction('editAdminColUser'),


    }

const mutations = {
    ...apiTools.getMutations([
        'sysGroup',
        'userList',
        'colUserList',
        'adminColUserList',
        'roleList',
        'menuListTree',
        'allRoleList',
        'appAllRoleList',
    ], { types, CONST_REQUEST })
}


export default {
    namespaced: true,
    state: getDefaultState(),
    getters,
    actions,
    mutations
}