// with polyfills
import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import i18n from './i18n';
import App from './App.vue'
import router from './router'
import store from './store/'
import mixins from './mixins'
import { VueAxios } from './utils/request'
// import preview from 'vue-photo-preview'
// import 'vue-photo-preview/dist/skin.css'
import pagination from './views/modules/pagination';
[
    pagination,
].forEach(component => {
    Vue.use(component);
    Vue.component('vPagination', pagination)
});
[
    mixins,
].forEach(plugin => Vue.use(plugin));

// mock
// WARNING: `mockjs` NOT SUPPORT `IE` PLEASE DO NOT USE IN `production` ENV.
// import './mock'

import bootstrap from './core/bootstrap'
import './core/lazy_use'
import './permission' // permission control
import './utils/filter' // global filter
import './components/global.less'

Vue.config.productionTip = false

// mount axios Vue.$http and this.$http
Vue.use(VueAxios)
// Vue.use(preview)

new Vue({
  router,
  store,
  i18n,
  created: bootstrap,
  render: h => h(App)
}).$mount('#app')
