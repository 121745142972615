export default {
  zh: {
    requestDate: '日期',
    installNum: '安装',
    transRate: '转化',
    regSubMobileDistinctNum: '提交未注册号码去重数',
    regSubMobileNum: '提交未注册号码次数',
    avgSubNum: '平均提交次数',
    regSubValidMobileDistinctNum: '提交合法未注册号码去重数',
    subValidMobileProportion: '提交合法号码占比',
    regSendOtpNum: '发送注册OTP的短信条数',
    regSendOtpMobileDistinctNum: '发送注册OTP的号码去重数',
    avgSendNumEveryMobile: '平均每个号码发送条数',
    regOperateSucceedNum: '注册成功号码数',
    regOperateFailNum: '未注册成功号码数',
    unLoginNum: '未请求登录接口号码数',
    lockOtpNum: '锁死OTP用尽号码数',
    regLockOperateNum: '锁死未注册号码数',
    regSuccessRateByTime: '注册成功率(按次)',
    regSuccessRateByMobile: '注册成功率(按号码)',
    loginSubMobileDistinctNum: '提交登录号码去重数',
    loginSubValidMobileDistinctNum: '提交的合法的号码去重数',
    sendOtpNum: '发送OTP的短信条数',
    loginSendOtpMobileDistinctNum: '发送OTP的号码去重数',
    loginOperateSucceedNum: '登录成功号码数',
    loginOperateFailNum: '登录失败号码数',
    loginLockOperateNum: '锁死未登录号码数',
    loginSuccessRateByTime: '登录成功率(按次)',
    loginSuccessRateByMobile: '登录成功率(按号码)',
    loginSubMobileNum: '提交登录号码次数',
  },
  en: {
    requestDate: 'Date',
    installNum: 'Install num',
    transRate: 'Translate rate',
    regSubMobileDistinctNum: 'Submit mobile distinct num',
    regSubMobileNum: 'Submit mobile num',
    avgSubNum: 'Avg Submit Num',
    regSubValidMobileDistinctNum: 'Submit valid mobile distinct num',
    subValidMobileProportion: 'Submit valid mobile proportion',
    regSendOtpNum: 'Send otp num',
    regSendOtpMobileDistinctNum: 'Send otp mobile distinct num',
    avgSendNumEveryMobile: 'Avg send num every mobile',
    regOperateSucceedNum: 'Operate succeed num',
    regOperateFailNum: 'Operate fail num',
    unLoginNum: 'Unlogin num',
    lockOtpNum: 'Lock otp num',
    regLockOperateNum: 'Lock operate num',
    regSuccessRateByTime: 'Success rate by time',
    regSuccessRateByMobile: 'Success rate by mobile',
    loginSubMobileDistinctNum: 'Login sub mobile distinct num',
    loginSubValidMobileDistinctNum: 'Login sub valid mobile distinct num',
    sendOtpNum: 'Send otp num',
    loginSendOtpMobileDistinctNum: 'Login send otp mobile distinct num',
    loginOperateSucceedNum: 'Login operate succeed num',
    loginOperateFailNum: 'Login operate fail num',
    loginLockOperateNum: 'Login lock operate num',
    loginSuccessRateByTime: 'Login success rate by time',
    loginSuccessRateByMobile: 'Login success rate by mobile',
    loginSubMobileNum: 'Login submit mobile num',
  }
}
