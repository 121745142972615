import api from './index'
import { axios } from '@/utils/request'

export function getAccountList (parameter = {}) {
  return axios({
    url: '/account/list',
    method: 'post',
    data: parameter
  })
}

export function addAccount (parameter = {}) {
  return axios({
    url: '/account/add',
    method: 'post',
    data: parameter
  })
}

export function editAccount (parameter = {}) {
  return axios({
    url: '/account/edit',
    method: 'post',
    data: parameter
  })
}

export function deleteAccount (parameter = {}) {
  return axios({
    url: '/account/delete',
    method: 'post',
    data: parameter
  })
}

export function getProductList (parameter = {}) {
  return axios({
    url: '/product/list',
    method: 'post',
    data: parameter
  })
}

export function getProductItemList (parameter = {}) {
  return axios({
    url: '/product/case/list',
    method: 'post',
    data: parameter
  })
}

export function addProductItem (parameter = {}) {
  return axios({
    url: '/product/case/add',
    method: 'post',
    data: parameter
  })
}

export function editProductItem (parameter = {}) {
  return axios({
    url: '/product/case/edit',
    method: 'post',
    data: parameter
  })
}

export function addProductConfig (parameter = {}) {
  return axios({
    url: '/normalConfig/add',
    method: 'post',
    data: parameter
  })
}

export function editProductConfig (parameter = {}) {
  return axios({
    url: '/normalConfig/edit',
    method: 'post',
    data: parameter
  })
}

export function getProductConfig (parameter = {}) {
  return axios({
    url: '/normalConfig/list',
    method: 'post',
    data: parameter
  })
}


export function getQueueList (parameter = {}) {
  return axios({
    url: '/queue/list',
    method: 'post',
    data: parameter
  })
}

export function addQueueList (parameter = {}) {
  return axios({
    url: '/queue/add',
    method: 'post',
    data: parameter
  })
}

export function deleteQueueList (parameter = {}) {
  return axios({
    url: '/queue/delete ',
    method: 'post',
    data: parameter
  })
}
