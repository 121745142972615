<template>
  <div>
    <div id="container"></div>
  </div>
</template>
<script>
  import G2 from '@antv/g2';
  const DataSet = require('@antv/data-set')

const scale = [{
    dataKey: 'percent',
    min: 0,
    formatter: '.0%',
}]
export default {
    props: {
        height: {
            type: Number,
            default: 0
        },
        width: {
            type: Number,
            default: 0
        },
        title: {
            type: String,
            default: ''
        },
        data: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data () {
        return {
            scale,
        };
    },
    watch: {
      data (val) {
        val && this.drawChart(val);
      }
    },
    mounted () {
      this.drawChart(this.data);
    },
    methods: {
        drawChart(data) {
            const { height } = this;
            this.chart && this.chart.destroy();
            this.chart = new G2.Chart({
                container: this.$el,
                forceFit: true,
                height: height,
                scale,
                padding: 'auto',
            });
            const dv = new DataSet.View().source(this.data);
            dv.transform({
                type: 'percent',
                field: 'count',
                dimension: 'item',
                as: 'percent'
            });
            this.chart.coord('theta', {
                radius: 0.75,
            });
            this.chart.source(dv.rows);
            this.chart.tooltip({ showTitle: false, title: this.title })

            this.chart.intervalStack().position('percent').color('item')
            .label('percent', {
                formatter: (val, item) => {
                  return item.point.item + ': ' + (val * 100).toFixed(3) + '%';
                }
            })
            .tooltip('item*percent', (item, percent) => {
                percent = percent * 100 + '%';
                return {
                  name: item,
                  value: percent
                };
            })

            this.chart.render();
        }
    }
};
</script>

