export default {
  zh: {
    workplaceName: '职场名称',
    personInCharge: '职场负责人',
    workplacePositon: '职场位置',
    workplaceArea: '职场面积(m²)',
    officeCubicle: '总工位数',
    departmentName: '部门名称',
    workplaceCashPledge: '职场押金',
    decorationCost: '装修费用',
    rentMonthly: '职场月租金',
    propertyFee: '物业费',
    networkExpenseMonthly: '职场网络费用/月',
    chargeWaterMonthly: '每月水费',
    powerFeeMonthly: '每月电费',
    otherFee: '其他费用',
    group: '小组',
    totalCollectNum: '分单数量',
    totalCollectAmt: '分单金额',
    collectStage: '案件等级',
    basicKpi: '基础绩效',
    kpiDiff: '相差绩效',
    taskNumRate: '当前绩效(订单维度)',
    taskAmtRate: '当前绩效(金额维度)',
    busiDate: '日期',
    jexlType: 'Jexl 类型',
    createdTime: '创建时间',
    createdBy: '创建人id',
    updatedTime: '更新时间',
    updatedBy: '更新人id',
    test: '测试',
    jexlParams: 'Jexl 参数',
    chooseFile:'选择文件',
    upload:"上传",
    compare:"比较",
    detail:"详情",
    appSsid:"盘",
    force:"生效"

  },
  en: {
    workplaceName: 'Workplace name',
    personInCharge: 'Person in charge',
    workplacePositon: 'Workplace positon',
    workplaceArea: 'Workplace area(m²)',
    officeCubicle: 'Office cubicle',
    departmentName: 'Department name',
    workplaceCashPledge: 'Workplace cash pledge',
    decorationCost: 'Decoration cost',
    rentMonthly: 'Rent monthly',
    propertyFee: 'Property fee',
    networkExpenseMonthly: 'Network expense monthly',
    chargeWaterMonthly: 'Charge water monthly',
    powerFeeMonthly: 'Power fee monthly',
    otherFee: 'Other fee',
    group: 'Group',
    totalCollectNum: 'Total Collect Number',
    totalCollectAmt: 'Total Collect Amount',
    collectStage: 'Collect Stage',
    basicKpi: 'Basic Kpi',
    kpiDiff: 'Kpi Difference',
    taskNumRate: 'Task Number Kpi',
    taskAmtRate: 'Task Amount Kpi',
    busiDate: 'Busi Date',
    jexlType: 'Jexl type',
    jexlExpression: 'Jexl expression',
    createdBy: 'Created by',
    updatedTime: 'Updated time',
    updatedBy: 'Updated by',
    test: 'Test',
    jexlParams: 'Jexl params',
    chooseFile:'Choose File',
    upload:"Upload",
    compare:"Compare",
    detail:"Detail",
    appSsid:"AppSsid",
    force:"force"
  }
}
