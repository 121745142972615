<template>
  <div>
    <div id="container"></div>
  </div>
</template>
<script>
  import G2 from '@antv/g2';
const scale = [{
  dataKey: 'value',
  min: 0,
},{
  dataKey: 'year',
  min: 0,
}];
export default {
    props: {
        height: {
            type: Number,
            default: 0
        },
        width: {
            type: Number,
            default: 0
        },
        title: {
            type: String,
            default: ''
        },
        data: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data () {
        return {
            scale,
        };
    },
    watch: {
      data (val) {
        val && this.drawChart(val);
      }
    },
    mounted () {
      this.drawChart(this.data);
    },
    methods: {
        drawChart(data) {
            const { height } = this;
            this.chart && this.chart.destroy();
            this.chart = new G2.Chart({
                container: this.$el,
                forceFit: true,
                height: height,
                scale,
                padding: ['auto', 'auto', 40, 50],
            });
            this.chart.source(data);
            this.chart.tooltip({ showTitle: false, title: this.title })

            this.chart.line().position('x*y').size(1).shape('smooth').tooltip('x*y', (x, y) => ({ name: x, value: y }));
            this.chart.point().position('x*y').shape('circle').tooltip('x*y', (x, y) => ({ name: x, value: y }));

            this.chart.render();
        }
    }
};
</script>
