import CONST_REQUEST from '../../constants/request';
import * as api from '../../api/order';
import apiTools from '../../utils/store'

const types = {
    ...apiTools.getTypes([
        'orderDetail',
        'orderInfoQryDetail',
        'orderDetailForCollection',
        'repayPlanList',
        'examineLogList',
        'collectionLogList',
        'orderNumberList',
        'addressBookList',
        'repayRecordList',
         'orderImageList'
    ]),
}

const getDefaultState = () => {
    return {
        ...apiTools.getStates([
            'orderDetail',
            'orderInfoQryDetail',
            'orderDetailForCollection',
            'repayPlanList',
            'examineLogList',
            'collectionLogList',
          'orderNumberList',
            'addressBookList',
            'repayRecordList',
          'orderImageList'
        ]),
    }
}

const getters = {
        ...apiTools.getGetters([
            'orderDetail',
            'orderInfoQryDetail',
            'orderDetailForCollection',
            'repayPlanList',
            'examineLogList',
            'collectionLogList',
          'orderNumberList',
            'addressBookList',
            'repayRecordList',
          'orderImageList'
        ])
    }

const buildAction = (apiName) => {
    return ({ commit }, params) => {
        return api[apiName](params).then((res) => {
            if (res.code == CONST_REQUEST.CODE_SUCCESS) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    }
}

const actions = {
        ...apiTools.getActions([
            'getOrderDetail',
            'getOrderInfoQryDetail',
            'getOrderDetailForCollection',
            'getRepayPlanList',
            'getExamineLogList',
            'getCollectionLogList',
            'getOrderNumberList',
            'getRepayRecordList',
            'getRepaymentReport',
            'downRepaymentReportExcel',
            'getAddressBookList',
            'getOrderImageList',
            'getOverdueReportRate',
            'downOverdueReportExcel'

        ], { types, api, CONST_REQUEST }),
    }

const mutations = {
    ...apiTools.getMutations([
        'orderDetail',
        'orderInfoQryDetail',
        'orderDetailForCollection',
        'repayPlanList',
        'examineLogList',
        'collectionLogList',
        'addressBookList',
        'orderNumberList',
        'repayRecordList',
      'orderImageList'

    ], { types, CONST_REQUEST })
}


export default {
    namespaced: true,
    state: getDefaultState(),
    getters,
    actions,
    mutations
}