export default {
  zh: {
    id: 'ID',
    country: '国家',
    createTime: '生成时间',
    custMobile: '客户手机号',
    custEmail: '客户邮箱',
    app: '包',
    status: '工单状态',
    status1: '未处理',
    status2: '处理完成',
    type: '工单类型',
    firstLevelQuestion: '一级标签',
    secondLevelQuestion: '二级标签',
    complainChannel: '客诉渠道',
    custAppeal: '客户诉求',
    attachmentCode: '附件',
    attachment: '附件',
    collectionDate: '催收日期',
    collectionUser: '催收用户',
    collectionMobile: '催收手机号',
    remark: '备注',
    action: '操作',
    custQueryData: '手机/邮箱',
    createdTime: '创建时间',
    days:'时间范围',
    workOrderDetail: '工单详情',
    status_0: '未处理',
    status_1: '关闭',
    status_2: '客服处理中',
    status_3: '平账处理中',
    status_4: '催收处理中',
    status_5: '产品处理中',
    status_6: '系统处理中',
    status_7: '客服管理员处理中',
    type_1: '投诉',
    type_2: '咨询',
    type_0: '未知',
    firstLevelQuestion_1: 'APP问题',
    firstLevelQuestion_2: '催收',
    firstLevelQuestion_3: '广告',
    firstLevelQuestion_4: '销号',
    firstLevelQuestion_5: '借款',
    firstLevelQuestion_6: '还款',
    firstLevelQuestion_7: '展期',
    firstLevelQuestion_8: '退款',
    secondLevelQuestion_1_1: '产品功能BUG',
    secondLevelQuestion_1_2: '本地化问题',
    secondLevelQuestion_1_3: '咨询使用方法',
    secondLevelQuestion_1_4: '修改个人信息',
    secondLevelQuestion_1_5: '咨询下载地址',
    secondLevelQuestion_2_1: '暴力催收',
    secondLevelQuestion_2_2: '催收员欺诈',
    secondLevelQuestion_3_1: '广告欺诈',
    secondLevelQuestion_4_1: '删除账号',
    secondLevelQuestion_5_1: '利息税率',
    secondLevelQuestion_5_2: '借款金额',
    secondLevelQuestion_5_3: '还款周期',
    secondLevelQuestion_5_4: '到账时间',
    secondLevelQuestion_5_5: '借款拒绝',
    secondLevelQuestion_5_6: '借款取消',
    secondLevelQuestion_6_1: '已还款未生效/系统问题无法还款/支付转账限额无法还款/多次还款/代扣',
    secondLevelQuestion_6_2: '咨询如何还款/咨询征信问题',
    secondLevelQuestion_7_1: '展期付款理解为部分付款/展期付款已付款未生效',
    secondLevelQuestion_7_2: '咨询展期付款',
    secondLevelQuestion_8_1: '申请退款',
    secondLevelQuestion_8_2: '咨询退款到账时间',
    complainChannel_1: 'whatsapp',
    complainChannel_2: 'Phone',
    complainChannel_3: 'Email',
    complainChannel_4: 'crisp牌照公司',
    complainChannel_5: '监管机构',
    complainChannel_6: '支付机构',
    complainChannel_7: '法院',
    complainChannel_8: '检察院',
    complainChannel_9: '催收专线',
    complainChannel_10: '用户提交',
    custAppeal_1: '快速批准',
    custAppeal_2: '退款',
    custAppeal_3: '正常还款',
    custAppeal_4: '提高额度',
    custAppeal_5: '快速到账',
    custAppeal_6: 'APP流畅使用',
    custAppeal_7: '减免',
    custAppeal_8: '改订单状态',
    custAppeal_9: '降低利率',
    custAppeal_10: '延长还款周期',
    custAppeal_11: '推迟还款',
    custAppeal_12: '取消贷款订单',
    custAppeal_13: '其他',
    canPass_1: '是',
    canPass_2: '否',
    item: '项目',
    value: '值',
    lastTwoWeekValue: '上上周',
    lastWeekValue: '上周',
    rate: '比例',
    dateLabel: '日期标签',
    reportType: '类型',
    totalCount: '工单总数',
    complaint: '投诉',
    consult: '咨询',
    reportType_1: '日报',
    reportType_2: '周报',

    dictType: '字典类型',
    dictKey: '字典键',
    dictValue: '字典值',
    dictStatus: '字典状态',
    dictSort: '字典排序',
    operate: '操作',
    dictStatus_0: '启用',
    dictStatus_1: '禁用',

    version: '版本号',
    versionContent: '版本内容',
    forceUpdate: '是否强更',
    downloadUrl: '下载路径',
    forceUpdate_0: '不强更',
    forceUpdate_1: '强更',
    updateTime: '更新时间',
    proportion: '占比',
    account: '客服账号',
    questionV1: '问题标签',
    inputOptRemark: '请输入操作备注',
    optRemarkMustNotNull: "操作备注不能为空",
    optRemark: '操作备注',
    questionV1_undefined: ' ',
    questionV1_0: ' ',
    questionV1_1: '借款BUG',
    questionV1_2: '还款BUG',
    questionV1_3: '验证码',
    questionV1_4: '暴力催收',
    questionV1_5: '催收员欺诈',
    questionV1_6: '广告问题',
    questionV1_7: '审核时间太长',
    questionV1_8: '到账时间太长',
    questionV1_9: '借款拒绝',
    questionV1_10: '借款取消',
    questionV1_11: '还款未生效',
    questionV1_12: '重复还款',
    questionV1_13: '代扣',
    questionV1_14: '征信',
    questionV1_15: '展期付款理解为部分还款',
    questionV1_16: '账号信息修改',
    days_all: '全部',
    days_yesterday: '昨天',
    days_today: '今天',
    days_last7days: '最近七天',
    days_last1month: '最近一月',
    days_other: '其他',
    approve: '审批',
    operateType: '操作类型',
    operateType_0: '审批拒绝',
    operateType_1: '审批通过',
    operateType_2: '转交催收',
    operateType_3: '补充资料',
    processFlow: '工作流',
    nodeId: '节点',
    businessNode: '业务节点',
    businessNode_cs_receive_complaint: '客服接到投诉',
    businessNode_coll_receive_complaint: '催收发现客户投诉',
    businessNode_cs_add_work_order: '客服录入工单',
    businessNode_coll_add_work_order: '催收录入工单',
    businessNode_notice_coll: '系统通知催收',
    businessNode_notice_ba: '系统通知平账组',
    businessNode_notice_cs: '系统通知客服',
    businessNode_notice_pro: '系统通知产品',
    businessNode_notice_cs_send_warning: '系统通知客服发送警告函',
    businessNode_cs_back_visit: '客服回访',
    businessNode_ba_approve: '平账组审核工单',
    businessNode_coll_examine_coll_process: '催收主管核查催收过程',
    businessNode_pro_handle_problem: '产品修改问题',
    businessNode_notice_cs_manage: '系统通知客服管理员',
    businessNode_cs_manage_replace_collection_user: '客服管理员更换催收员',
    itNode: 'it节点',
    itNode_receive_complaint: '收到投诉',
    itNode_add_work_order: '新增工单',
    itNode_notice_coll: '通知催收',
    itNode_notice_cs: '通知客服',
    itNode_notice_ba: '通知平账组',
    itNode_notice_pro: '通知产品',
    itNode_send_warning: '发送警告函',
    itNode_approve: '审批',
    itNode_cs_back_visit: '客服回访',
    itNode_coll_examine: '催收核查',
    itNode_notice_cs_manage: '通知客服管理员',
    itNode_replace_collection_user: '更换催收员',
    nodeType: '节点类型',
    nodeType_0: '收到投诉',
    nodeType_1: '新增工单',
    nodeType_2: '通知',
    nodeType_3: '审批',
    operater: '操作人',
    flowStatus: '状态',
    flowStatus_0: '进行中',
    flowStatus_1: '关闭',
    repair: '修复',
    repairNotice: '修复卡流程案子',
    operateTime: '操作时间',
    approveResult: '审批意见',
    uploadImage: '上传图片',
    upload: '上传',
    nextApproveRole: '下级审批角色',
    approveRole_0: '系统',
    approveRole_1: '客服',
    approveRole_2: '平账',
    approveRole_3: '催收',
    approveRole_4: '产品',
    approveRole_5: '客服管理员',
    nextApproveUser: '下级审批人员',
    hasImage: '有图片',
    workFlowType: '工单类型',
    workFlowType_common: '普通(客服)',
    workFlowType_violentCollection: '暴力催收(客服)',
    workFlowType_violentCollectionForAppUser: '暴力催收(客户)',
    workFlowType_balanceAccountForCustomerService: '平账问题(客服)',
    workFlowType_balanceAccountForCollcetor: '平账问题(催收)',
    workFlowType_preLoan: '产品问题(客服)',
    workFlowType_commonForCustomerServiceManage: '普通(客服管理员)',
    workFlowType_violentCollectionForCustomerServiceManage: '暴力催收(客服管理员)',
    workFlowType_balanceAccountForCustomerServiceManage: '平账问题(客服管理员)',
    workFlowType_preLoanForCustomerServiceManage: '产品问题(客服管理员)',
    baAmount: '平账金额',
    decision: '决策',
    decision_1: '更换催收员',
    decision_2: '产品已知晓',
    decision_3: '减免平账',
    decision_4: '免费全额平账',
    decision_5: '退回本金平账',
    decision_6: '免费展期',
    decision_7: '减免展期',
    decision_8: '退款',
    decision_9: '客户欺诈',
    imageLoadingNotice: '图片上传中...',
    operateTypeNotice: '请选择审批意见',
    productRemarkNotice: '请填写备注',
    unknow: '未知',
    create: '创建工单',
    WorkOrderCreate: '创建工单',
    workOrderCreate: '创建工单',
    custId: '客户ID',
    orderNo: '订单编号',
    workFlowTypeNotice: '请选择工单类型',
    questionV1Notice: '请选择问题标签',
    approvelUserNotice: '请选择下级审批人',
    custMobileAndCustEmailNotice: '请输入客户手机号或邮箱',
    appsNotice: '请选择包',
    complainChannelNotice: '请选择投诉渠道',
    processFlows: '流转历史',
    video: '视频',
    videoPreviewNotice: '您的浏览器不支持',
    videoSizeNotice_pre: '视频最大支持',
    videoSizeNotice_suf: 'M',
    videoLoadingNotice: '视频上传中...',
    uploadVideo: '上传视频',
    videoTypeNotice: '视频格式错误',
    imageSizeNotice_pre: '图片最大支持',
    imageSizeNotice_suf: 'M',
    imageTypeNotice: '图片格式错误',
    decisionNotice: '请选择决策',
    baAmountNotice: '请填写平账金额',
    collectionDateNotice: '请输入催收日期',
    save: '保存',
    level: '投诉级别',
    level_1: '用户欺诈',
    level_2: '催收员欺诈',
    level_3: '普通投诉',
    level_4: '严重投诉',
    level_5: '拒绝沟通',
    handleStatus: '处理状态',
    handleStatus_0: '进行中',
    handleStatus_1: '已完成',
    satisfaction: '满意度',
    satisfaction_0: '无',
    satisfaction_1: '不满意',
    satisfaction_2: '满意',
    userMobile: '用户输入手机号',
    name: '用户姓名',
    surname: '用户姓氏',
    appUser: '用户提交',
    backVisit: '是否回访',
    backVisit_0: '未回访',
    backVisit_1: '已回访',
    connect: '是否接通',
    connect_0: '未接通',
    connect_1: '已接通',
    complaintNum: '投诉数量',
    backVisitNum: '回访数量',
    connectNum: '接通数量',
    satisfactionNum: '满意数量',
    connectRate: '接通率',
    satisfactionRate: '满意度',
    coverRate: '覆盖率',
    exportWithImage: '导出(带图)',
    downloadWithImageNotice: '只支持单日日期',
    settle: '是否付款',
    settle_all: '全部',
    settle_0: '未付款',
    settle_1: '已付款',
    dataType: '数据类型',
    dataType_commonData: '普通数据',
    dataType_top10CollctionUserData: '前10名催收员数据',
    topNum: '前几名',
    lastId: '上次导出最后ID',
    limitNum: '导出量',
    topStatus: '置顶情况',
    topStatus_0: '不置顶',
    topStatus_1: '需置顶',
    exportWithAllcolumns: '导出全部字段',
    tag: '模型标签',
    tag_1: '暴力催收',
    tag_2: '盗取个人信息',
    tag_3: '额度低',
    tag_4: '利息高',
    tag_5: '期限短',
    tag_6: '欺诈',
    tag_7: '审核时间长',
    tag_8: '借款未到账',
    tag_9: '还款未显示',
    tag_10: '借款被拒',
    tag_11: '不推荐',
    tag_12: '提前催收',
    tag_13: '骚扰联系人',
    tag_14: 'App bug',
    tag_15: '无法还款',
    tag_16: '到账慢',
    tag_17: '到账失败',
    tag_18: '删除账号资料',
    tag_19: '取消借款',
    tag_0: '记录数',
    settleStatus: '结清状态',
    settleStatus_0: '未结清',
    settleStatus_1: '提前结清',
    settleStatus_2: '正常结清',
    settleStatus_3: '逾期结清',
    settleStatus_4: '展期结清',
    settleStatus_5: '逾期展期结清',
    settleStatus_6: '异常结清',
    settleStatus_no: '不区分',
    source: '数据来源',
    source_1: '客服APP录入',
    source_2: 'web后台录入',
    source_3: 'H5页面录入',
    source_4: '前端客服录入',
    approvalStatus: '审批状态',
    'approvalStatus_-1': '未提交',
    approvalStatus_0: '机审中',
    approvalStatus_1: '机审拒绝',
    approvalStatus_2: '人工审批中',
    approvalStatus_3: '人工拒绝',
    approvalStatus_4: '审批通过',
    approvalStatus_5: 'cancel',
    approvalStatus_6: '大王贷审批通过',
    aiType: 'AI类型',
    aiType_0: '未知',
    aiType_1: '投诉',
    aiType_2: '咨询',
    resolveStatus: '解决状态',
    resolveStatus_0: '未解决',
    resolveStatus_1: '已解决',
    frontSource: '前端来源页面',
    frontSource_1: '首页未登录',
    frontSource_2: '首页已登录',
    frontSource_3: '多产品首页已登录',
    frontSource_4: '我的页面',
    frontSource_5: 'info1',
    frontSource_6: 'info2',
    frontSource_7: 'info3',
    frontSource_8: 'info4',
    frontSource_9: 'info5',
    frontSource_10: '金额选择页面',
    frontSource_11: '审核中',
    frontSource_12: '放款中',
    frontSource_13: '放款失败',
    frontSource_14: '审核拒绝',
    frontSource_15: '放款失败重新绑卡页面',
    frontSource_16: '待还款',
    frontSource_17: '逾期还款',
    frontSource_18: '订单页',
    frontSource_19: '催收投诉页',
    orderType: '订单类型',
    orderType_0: '单期订单',
    orderType_1: '分期订单',
    dictTextCn: '中文',
    dictTextEn: '英语',
    dictTextEs: '西语',
    dictTextFr: '法语',
    installmentSettleStatus_0: '未结清',
    installmentSettleStatus_1: '已结清',
    orderUnRepayAmount: '未还总金额',
    curCapital: '当期本金',
    curRepayAmount: '当期应还金额',
    curOrderUnRepayAmount: '当期未还总金额',
    toFinance: '转财务',
    toFinance_all: '全部',
    toFinance_0: 'No',
    toFinance_1: 'Yes',
    handleUser: '处理用户',
    handleTime: '处理时间',
    subject: '主题',
    information: '内容',
  },
  en: {
    id: 'ID',
    country: 'Country',
    createTime: 'Create Time',
    custMobile: 'Customer Phone',
    custEmail: 'Customer Email',
    app: 'APP',
    status: 'Status',
    status1: 'Don\'t Handle',
    status2: 'Handle Complete',
    type: 'Type',
    firstLevelQuestion: 'First Level Label',
    secondLevelQuestion: 'Second Level Label',
    complainChannel: 'Complain Channel',
    custAppeal: 'CUstomer Appeal',
    attachmentCode: 'Attachment',
    attachment: 'Attachment',
    collectionDate: 'Collection Date',
    collectionUser: 'Collection User',
    collectionMobile: 'Collection Mobile',
    remark: 'Remark',
    action: 'Operate',
    custQueryData: 'Mobile/Email',
    createdTime: 'Create Time',
    days: 'Time Range',
    workOrderDetail: 'Detail',
    status_0: 'New',
    status_1: 'Close',
    status_2: 'Customer Service Processing',
    status_3: 'Balance Account Processing',
    status_4: 'Collection Processing',
    status_5: 'Product Processing',
    status_6: 'System Processing',
    status_7: 'Customer Service Manager Processing',
    type_1: 'Complain',
    type_2: 'Consult',
    type_0: 'unknown',
    firstLevelQuestion_1: 'App Problem',
    firstLevelQuestion_2: 'Collection',
    firstLevelQuestion_3: 'Advertise',
    firstLevelQuestion_4: 'Log out',
    firstLevelQuestion_5: 'Loan',
    firstLevelQuestion_6: 'Repayment',
    firstLevelQuestion_7: 'Rollover',
    firstLevelQuestion_8: 'Refund',
    secondLevelQuestion_1_1: 'Product function BUG',
    secondLevelQuestion_1_2: 'localization issues',
    secondLevelQuestion_1_3: 'How to use it',
    secondLevelQuestion_1_4: 'Modify Personal Information',
    secondLevelQuestion_1_5: 'Consult download address',
    secondLevelQuestion_2_1: 'violent collection',
    secondLevelQuestion_2_2: 'collector fraud',
    secondLevelQuestion_3_1: 'ad fraud',
    secondLevelQuestion_4_1: 'delete account',
    secondLevelQuestion_5_1: 'interest tax rate',
    secondLevelQuestion_5_2: 'Loan amount',
    secondLevelQuestion_5_3: 'repayment cycle',
    secondLevelQuestion_5_4: 'Arrival time',
    secondLevelQuestion_5_5: 'loan rejection',
    secondLevelQuestion_5_6: 'loan cancellation',
    secondLevelQuestion_6_1: 'The repayment has not taken effect/the system problem cannot repay the payment/the payment transfer limit cannot be repaid/multiple repayments/withholding',
    secondLevelQuestion_6_2: 'Consultation on how to repay the loan/consultation on credit information',
    secondLevelQuestion_7_1: 'Extended payment is understood as partial payment / extended payment has been paid but not effective',
    secondLevelQuestion_7_2: 'Inquire about Rollover Payments',
    secondLevelQuestion_8_1: 'Request a refund',
    secondLevelQuestion_8_2: 'Inquiry about refund arrival time',
    complainChannel_1: 'whatsapp',
    complainChannel_2: 'Phone',
    complainChannel_3: 'Email',
    complainChannel_4: 'Crisp license company',
    complainChannel_5: 'Regulatory Authority',
    complainChannel_6: 'payment institution',
    complainChannel_7: 'court',
    complainChannel_8: 'Procuratorate',
    complainChannel_9: 'Collection Hotline',
    complainChannel_10: 'User Submissions',
    custAppeal_1: 'quick approval',
    custAppeal_2: 'Refund',
    custAppeal_3: 'normal repayment',
    custAppeal_4: 'increase quota',
    custAppeal_5: 'Fast payment',
    custAppeal_6: 'Smooth use of APP',
    custAppeal_7: 'deduction',
    custAppeal_8: 'change order status',
    custAppeal_9: 'lower interest rates',
    custAppeal_10: 'Extend the repayment period',
    custAppeal_11: 'deferment of repayment',
    custAppeal_12: 'cancel loan order',
    custAppeal_13: 'other',
    canPass_1: 'yes',
    canPass_2: 'no',
    item: 'item',
    value: 'Value',
    lastTwoWeekValue: 'Last Two Week',
    lastWeekValue: 'Last Week',
    rate: 'Rate',
    dateLabel: 'Date Label',
    reportType: 'Report Type',
    totalCount: 'Total',
    complaint: 'Complaint',
    consult: 'Consult',
    reportType_1: 'Day',
    reportType_2: 'Week',

    dictType: 'Dict Type',
    dictKey: 'Dict Key',
    dictValue: 'Dict Value',
    dictStatus: 'Status',
    dictSort: 'Sort',
    operate: 'Operate',
    dictStatus_0: 'enable',
    dictStatus_1: 'disable',

    version: 'Version',
    versionContent: 'Content',
    forceUpdate: 'Force update',
    downloadUrl: 'Download Url',
    forceUpdate_0: 'Dont force update',
    forceUpdate_1: 'Force update',
    updateTime: 'Update Time',
    proportion: 'proportion',
    account: 'Customer Service Account',
    questionV1: 'Question Label',
    inputOptRemark: 'Please enter operation notes',
    optRemarkMustNotNull: "Operation notes cannot be empty",
    optRemark: 'Operter Remark',
    questionV1_undefined: ' ',
    questionV1_0: ' ',
    questionV1_1: 'loan bug',
    questionV1_2: 'repayment bug',
    questionV1_3: 'OTP',
    questionV1_4: 'violent collection',
    questionV1_5: 'collector fraud',
    questionV1_6: 'ad fraud',
    questionV1_7: 'Review time is too long',
    questionV1_8: 'Arrival time too long',
    questionV1_9: 'loan rejection',
    questionV1_10: 'loan cancellation',
    questionV1_11: 'The repayment has not taken effect',
    questionV1_12: 'multiple repayments',
    questionV1_13: 'withholding',
    questionV1_14: 'credit information',
    questionV1_15: 'Extended payment is understood as partial payment',
    questionV1_16: 'Account Information Modification',
    days_all: 'All',
    days_yesterday: 'Yesterday',
    days_today: 'Today',
    days_last7days: 'Last 7 Days',
    days_last1month: 'Last 1 Month',
    days_other: 'Other',
    approve: 'approval',
    operateType: 'Operate Type',
    operateType_0: 'approval reject',
    operateType_1: 'approval pass',
    operateType_2: 'deliver to collection',
    operateType_3: 'additional materials',
    processFlow: 'Work Flow',
    nodeId: 'Node',
    businessNode: 'Business Node',
    businessNode_cs_receive_complaint: 'Customer service receive cpmplaint',
    businessNode_coll_receive_complaint: 'Collection receive complaint',
    businessNode_cs_add_work_order: 'Customer Service add work order',
    businessNode_coll_add_work_order: 'Collection add work order',
    businessNode_notice_coll: 'System notice collection',
    businessNode_notice_ba: 'System notice balance account',
    businessNode_notice_cs: 'System notice customer service',
    businessNode_notice_pro: 'System notice product',
    businessNode_notice_cs_send_warning: 'System notice customer service send warning letter',
    businessNode_cs_back_visit: 'Customer service back visit',
    businessNode_ba_approve: 'Balance account approve',
    businessNode_coll_examine_coll_process: 'Collection examine collect process',
    businessNode_pro_handle_problem: 'Product handle problem',
    businessNode_notice_cs_manage: 'System notifies customer service manager',
    businessNode_cs_manage_replace_collection_user: 'The customer service manager replaces the collector',
    itNode: 'IT node',
    itNode_receive_complaint: 'Receive complaint',
    itNode_add_work_order: 'Add Work Order',
    itNode_notice_coll: 'Notice Collection',
    itNode_notice_cs: 'Notice Customer Service',
    itNode_notice_ba: 'Notice Balance Account',
    itNode_notice_pro: 'Notice Product',
    itNode_send_warning: 'Send Warning',
    itNode_approve: 'Approve',
    itNode_cs_back_visit: 'Customer Service Back Visit',
    itNode_coll_examine: 'Collection Examine',
    itNode_notice_cs_manage: 'Notify customer service manager',
    itNode_replace_collection_user: 'replace collector',
    nodeType: 'Node Type',
    nodeType_0: 'Receive complaint',
    nodeType_1: 'Add Work Order',
    nodeType_2: 'Notice',
    nodeType_3: 'Approve',
    operater: 'Operator',
    flowStatus: 'Status',
    flowStatus_0: 'Processing',
    flowStatus_1: 'Close',
    repair: 'Repair',
    repairNotice: 'Repair stuck process case',
    operateTime: 'operating time',
    approveResult: 'Approval comments',
    uploadImage: 'upload image',
    upload: 'Upload',
    nextApproveRole: 'subordinate approval role',
    approveRole_0: 'system',
    approveRole_1: 'customer service',
    approveRole_2: 'debt cancellation',
    approveRole_3: 'collection',
    approveRole_4: 'product',
    approveRole_5: 'Customer Service Manager',
    nextApproveUser: 'next level approver',
    hasImage: 'With pictures',
    workFlowType: 'Work order type',
    workFlowType_common: 'common',
    workFlowType_violentCollection: 'violent collection',
    workFlowType_violentCollectionForAppUser: 'violent collection',
    workFlowType_balanceAccountForCustomerService: 'balance problem',
    workFlowType_balanceAccountForCollcetor: 'balance problem',
    workFlowType_preLoan: 'product problem',
    workFlowType_commonForCustomerServiceManage: 'common',
    workFlowType_violentCollectionForCustomerServiceManage: 'violent collection',
    workFlowType_balanceAccountForCustomerServiceManage: 'balance problem',
    workFlowType_preLoanForCustomerServiceManage: 'product problem',
    baAmount: 'amount of debt canceled',
    decision: 'decision',
    decision_1: 'change the collector',
    decision_2: 'product department is already aware of',
    decision_3: 'debt deducted or canceled',
    decision_4: 'total free debt cancellation',
    decision_5: 'debt cancellation with principal refunded',
    decision_6: 'free extension',
    decision_7: 'extension deducted',
    decision_8: 'refund',
    decision_9: 'customer fraud',
    imageLoadingNotice: 'Image uploading...',
    operateTypeNotice: 'Please select approval comments',
    productRemarkNotice: 'Please fill in the remarks',
    unknow: 'unknown',
    create: 'Create Work Order',
    WorkOrderCreate: 'Create Work Order',
    workOrderCreate: 'Create Work Order',
    custId: 'customer ID',
    orderNo: 'order number',
    workFlowTypeNotice: 'Please select a work order type',
    questionV1Notice: 'Please select a question label',
    approvelUserNotice: 'Please select a subordinate approver',
    custMobileAndCustEmailNotice: 'Please enter the customer\'s mobile phone number or email',
    appsNotice: 'Please choose package',
    complainChannelNotice: 'Please select a complaint channel',
    processFlows: 'Circulation history',
    video: 'Video',
    videoPreviewNotice: 'Your browser does not support',
    videoSizeNotice_pre: 'The maximum video support is ',
    videoSizeNotice_suf: 'M',
    videoLoadingNotice: 'video uploading...',
    uploadVideo: 'upload video',
    videoTypeNotice: 'wrong video format',
    imageSizeNotice_pre: 'The picture supports up to ',
    imageSizeNotice_suf: 'M',
    imageTypeNotice: 'wrong image format',
    decisionNotice: 'Please select a decision',
    baAmountNotice: 'Please enter amount of debt canceled',
    collectionDateNotice: 'Please enter collection date',
    save: 'save',
    level: 'Complaint level',
    level_1: 'User fraud',
    level_2: 'collector fraud',
    level_3: 'Ordinary complaints',
    level_4: 'serious complaint',
    level_5: 'refuse communication',
    handleStatus: 'Processing status',
    handleStatus_0: 'in progress',
    handleStatus_1: 'completed',
    satisfaction: 'Satisfaction',
    satisfaction_0: 'none',
    satisfaction_1: 'Not satisfied',
    satisfaction_2: 'satisfy',
    userMobile: 'user input mobile',
    name: 'username',
    surname: 'User last name',
    appUser: 'User submission',
    backVisit: 'return visit',
    backVisit_0: 'No return visit',
    backVisit_1: 'Returned visit',
    connect: 'Connect',
    connect_0: 'Not connected',
    connect_1: 'Connected',
    complaintNum: 'number of complaints',
    backVisitNum: 'Number of return visits',
    connectNum: 'Number of connections',
    satisfactionNum: 'Number of satisfactory',
    connectRate: 'connection rate',
    satisfactionRate: 'Satisfaction',
    coverRate: 'Coverage',
    exportWithImage: 'Export(with pictures)',
    downloadWithImageNotice: 'Only single-day dates are supported',
    settle: 'Whether to pay',
    settle_all: 'all',
    settle_0: 'Unpaid',
    settle_1: 'Already paid',
    dataType: 'data type',
    dataType_commonData: 'Normal data',
    dataType_top10CollctionUserData: 'Data of the top 10 collectors',
    topNum: 'Several former',
    lastId: 'Last exported last ID',
    limitNum: 'Export volume',
    topStatus: 'Pinned situation',
    topStatus_0: 'Not pinned',
    topStatus_1: 'Need to be pinned',
    exportWithAllcolumns: 'Export all fields',
    tag: 'model tag',
    tag_1: 'violent collection',
    tag_2: 'stealing personal information',
    tag_3: 'low loan amount',
    tag_4: 'high interest rate',
    tag_5: 'short loan term',
    tag_6: 'fraud',
    tag_7: 'long review time',
    tag_8: 'the loan has not arrived',
    tag_9: 'repayment not shown',
    tag_10: 'loan refused',
    tag_11: 'not recommended',
    tag_12: 'early collection',
    tag_13: 'harassment contact',
    tag_14: 'App bug',
    tag_15: 'unable to repay',
    tag_16: 'slow loan arrival',
    tag_17: 'loan arrival failed',
    tag_18: 'delete account information',
    tag_19: 'cancel loan',
    tag_0: 'Number of records',
    settleStatus: 'Settle Status',
    settleStatus_0: 'Unsettled',
    settleStatus_1: 'Early settlement',
    settleStatus_2: 'Normal Settlement',
    settleStatus_3: 'Late settlement',
    settleStatus_4: 'Extended settlement',
    settleStatus_5: 'Late Extended Settlement',
    settleStatus_6: 'Abnormal settlement',
    settleStatus_no: 'Don\'t differentiate',
    source: 'Data Sources',
    source_1: 'Customer service APP entry',
    source_2: 'web background entry',
    source_3: 'H5 page entry',
    source_4: 'Front-end customer service entry',
    approvalStatus: 'Approval Status',
    'approvalStatus_-1': 'unsubmitted',
    approvalStatus_0: 'Under machine review',
    approvalStatus_1: 'Machine review rejected',
    approvalStatus_2: 'Under manual approval',
    approvalStatus_3: 'Manual rejection',
    approvalStatus_4: 'Approved',
    approvalStatus_5: 'cancel',
    approvalStatus_6: 'Dawang Loan approved',
    aiType: 'AI type',
    aiType_0: 'unknown',
    aiType_1: 'complaint',
    aiType_2: 'consult',
    resolveStatus: 'Resolution status',
    resolveStatus_0: 'unsolved',
    resolveStatus_1: 'solved',
    frontSource: 'Front-end source page',
    frontSource_1: 'Home page not logged in',
    frontSource_2: 'Home page logged in',
    frontSource_3: 'Multi-product home page logged in',
    frontSource_4: 'my page',
    frontSource_5: 'info1',
    frontSource_6: 'info2',
    frontSource_7: 'info3',
    frontSource_8: 'info4',
    frontSource_9: 'info5',
    frontSource_10: 'Amount selection page',
    frontSource_11: 'under review',
    frontSource_12: 'Loan in progress',
    frontSource_13: 'Loan failed',
    frontSource_14: 'Review rejection',
    frontSource_15: 'Loan failure and rebind card page',
    frontSource_16: 'Pending repayment',
    frontSource_17: 'Late payment',
    frontSource_18: 'Order page',
    frontSource_19: 'Collection complaint page',
    orderType: 'Order Type',
    orderType_0: 'Single-period order',
    orderType_1: 'Instalment order',
    dictTextCn: 'Chinese',
    dictTextEn: 'English',
    dictTextEs: 'Spanish',
    dictTextFr: 'French',
    installmentSettleStatus_0: 'Unsettled',
    installmentSettleStatus_1: 'Settled',
    orderUnRepayAmount: 'Total amount not repaid',
    curCapital: 'Current principal',
    curRepayAmount: 'Current amount to be repaid',
    curOrderUnRepayAmount: 'Current total amount not repaid',
    toFinance: 'Transfer to Finance',
    toFinance_all: 'All',
    toFinance_0: 'No',
    toFinance_1: 'Yes',
    handleUser: 'Handle user',
    handleTime: 'Handle time',
    subject: 'Subject',
    information: 'Content',
  },
  es: {
    id: 'ID',
    country: 'país',
    createTime: 'Tiempo de creación',
    custMobile: 'Número de teléfono del usuario',
    custEmail: 'correo electrónico del usuario',
    app: 'app',
    status: 'estado de orden de trabajo ',
    status1: 'sin procesar',
    status2: 'procesado',
    type: 'tipo de orden de trabajo',
    firstLevelQuestion: 'First Level Label',
    secondLevelQuestion: 'Second Level Label',
    complainChannel: 'canal',
    custAppeal: 'solicitud',
    attachmentCode: 'anexo',
    attachment: 'anexo',
    collectionDate: 'fecha de cobranza',
    collectionUser: 'asesor de cobranza',
    collectionMobile: 'número de teléfono del asesor',
    remark: 'observaciones',
    action: 'operaciones',
    custQueryData: 'Móvil/Correo electrónico',
    createdTime: 'Tiempo de creación',
    days:'plazo',
    workOrderDetail: 'detalles',
    status_0: 'Añadir',
    status_1: 'Cerrar',
    status_2: 'en procesamineto de ATC',
    status_3: 'en procesamineto de cierre de cuenta',
    status_4: 'en procesamineto de cobranza',
    status_5: 'en procesamineto de producto',
    status_6: 'en procesamineto de sistema',
    status_7: 'en procesamineto de Administrador de servicio al cliente',
    type_1: 'queja',
    type_2: 'consulta',
    type_0: 'incógnita',
    firstLevelQuestion_1: 'App Problem',
    firstLevelQuestion_2: 'Collection',
    firstLevelQuestion_3: 'Advertise',
    firstLevelQuestion_4: 'Log out',
    firstLevelQuestion_5: 'Loan',
    firstLevelQuestion_6: 'Repayment',
    firstLevelQuestion_7: 'Rollover',
    firstLevelQuestion_8: 'Refund',
    secondLevelQuestion_1_1: 'Product function BUG',
    secondLevelQuestion_1_2: 'localization issues',
    secondLevelQuestion_1_3: 'How to use it',
    secondLevelQuestion_1_4: 'Modify Personal Information',
    secondLevelQuestion_1_5: 'Consult download address',
    secondLevelQuestion_2_1: 'violent collection',
    secondLevelQuestion_2_2: 'collector fraud',
    secondLevelQuestion_3_1: 'ad fraud',
    secondLevelQuestion_4_1: 'delete account',
    secondLevelQuestion_5_1: 'interest tax rate',
    secondLevelQuestion_5_2: 'Loan amount',
    secondLevelQuestion_5_3: 'repayment cycle',
    secondLevelQuestion_5_4: 'Arrival time',
    secondLevelQuestion_5_5: 'loan rejection',
    secondLevelQuestion_5_6: 'loan cancellation',
    secondLevelQuestion_6_1: 'The repayment has not taken effect/the system problem cannot repay the payment/the payment transfer limit cannot be repaid/multiple repayments/withholding',
    secondLevelQuestion_6_2: 'Consultation on how to repay the loan/consultation on credit information',
    secondLevelQuestion_7_1: 'Extended payment is understood as partial payment / extended payment has been paid but not effective',
    secondLevelQuestion_7_2: 'Inquire about Rollover Payments',
    secondLevelQuestion_8_1: 'Request a refund',
    secondLevelQuestion_8_2: 'Inquiry about refund arrival time',
    complainChannel_1: 'whatsapp',
    complainChannel_2: 'llamada',
    complainChannel_3: 'correo',
    complainChannel_4: 'crisp',
    complainChannel_5: 'instituto regulador',
    complainChannel_6: 'empresa de pago',
    complainChannel_7: 'Tribunal',
    complainChannel_8: 'Fiscalía',
    complainChannel_9: 'Línea especial de cobranza',
    complainChannel_10: 'Envíos de usuarios',
    custAppeal_1: 'aprobación rápida',
    custAppeal_2: 'devolución',
    custAppeal_3: 'pago normal',
    custAppeal_4: 'aumento de monto',
    custAppeal_5: 'obtiene préstamo rápido',
    custAppeal_6: 'usa app fluido',
    custAppeal_7: 'deducción',
    custAppeal_8: 'cambiar estado de pedido',
    custAppeal_9: 'recudir el interés',
    custAppeal_10: 'prolongar plazo de pago',
    custAppeal_11: 'aplazar el pago',
    custAppeal_12: 'cancelar el préstamo',
    custAppeal_13: 'otro',
    canPass_1: 'si',
    canPass_2: 'no',
    item: 'item',
    value: 'valor',
    lastTwoWeekValue: 'dos semanas antes',
    lastWeekValue: 'las semana pasada',
    rate: 'Proporción',
    dateLabel: 'label de fecha',
    reportType: 'tipo',
    totalCount: 'número total de ordenes de trabajo',
    complaint: 'queja',
    consult: 'consulta',
    reportType_1: 'reporte diario',
    reportType_2: 'reporte semanal',

    dictType: 'Tipo de diccionario',
    dictKey: 'Claves del diccionario',
    dictValue: 'valor del diccionario',
    dictStatus: 'estado del diccionario',
    dictSort: 'Clasificación de diccionarios',
    operate: 'Operación',
    dictStatus_0: 'permitir',
    dictStatus_1: 'desactivado',

    version: 'versión',
    versionContent: 'contenido de versión',
    forceUpdate: 'Actualizaciones forzadas o no',
    downloadUrl: 'ruta de descargar',
    forceUpdate_0: 'sin actualización obligatoria',
    forceUpdate_1: 'forzar actualización',
    updateTime: 'tiempo de actualización',
    proportion: 'Proporción',
    account: 'cuenta de ATC',
    questionV1: 'label de preguntas',
    inputOptRemark: 'Introduzca una nota sobre la operación',
    optRemarkMustNotNull: "La nota de operación no puede estar vacía",
    optRemark: 'Observaciones operativas',
    questionV1_undefined: ' ',
    questionV1_0: ' ',
    questionV1_1: 'bug de pedido',
    questionV1_2: 'bug de pago',
    questionV1_3: 'OTP',
    questionV1_4: 'cobranza violenta',
    questionV1_5: 'fraude de cobradores',
    questionV1_6: 'problemas de publicidad',
    questionV1_7: 'El tiempo de validación demasiado largo',
    questionV1_8: 'Periodo de desembolso en cuenta es muy largo',
    questionV1_9: 'Rechazo de prestamo',
    questionV1_10: 'Cancelación de préstamos',
    questionV1_11: 'Pagos no validos',
    questionV1_12: 'Pagos repetitivos',
    questionV1_13: 'deduccion',
    questionV1_14: 'Eliminacion de historial de credito',
    questionV1_15: 'Entienden extension como pago parcial',
    questionV1_16: 'Modificación de datos de la cuenta',
    days_all: 'todo',
    days_yesterday: 'ayer',
    days_today: 'hoy',
    days_last7days: 'últimos siete días',
    days_last1month: 'el mes pasado',
    days_other: 'otro',
    approve: 'aprobación',
    operateType: 'Tipo de operación',
    operateType_0: 'Rechazado',
    operateType_1: 'Aprobado',
    operateType_2: 'colección de transferencia',
    operateType_3: 'Materiales adicionales',
    processFlow: 'flujo de trabajo',
    nodeId: 'etapa',
    businessNode: 'etapa de operación',
    businessNode_cs_receive_complaint: 'La atención al cliente ha recibido la queja',
    businessNode_coll_receive_complaint: 'La Cobranza ha recibido la queja',
    businessNode_cs_add_work_order: 'Atencion al cliente ha creado la orden de trabajo',
    businessNode_coll_add_work_order: 'Cobranza ha creado la orden de trabajo',
    businessNode_notice_coll: 'Notificación del sistema a Cobranza',
    businessNode_notice_ba: 'Notificación del sistema a Cierre de cuenta',
    businessNode_notice_cs: 'Notificación del sistema a Atención al cliente',
    businessNode_notice_pro: 'Notificación del sistema a Producto',
    businessNode_notice_cs_send_warning: 'Notificaciones del sistema a ATC para enviar cartas de alerta',
    businessNode_cs_back_visit: 'ATC devuelve a llamar a usuarios',
    businessNode_ba_approve: 'Revisión de orden de trabajo por Cierre de cuenta',
    businessNode_coll_examine_coll_process: 'El supervisor de Cobranza verifica el proceso de cobro',
    businessNode_pro_handle_problem: 'Depto de producto modifica el problema',
    businessNode_notice_cs_manage: 'El sistema notifica al administrador de servicio al cliente',
    businessNode_cs_manage_replace_collection_user: 'El administrador de servicio al cliente reemplaza al cobrador',
    itNode: 'etapa',
    itNode_receive_complaint: 'queja recibida',
    itNode_add_work_order: 'Añadir orden de trabajo',
    itNode_notice_coll: 'Notificación a Cobranza',
    itNode_notice_cs: 'Notificación a ATC',
    itNode_notice_ba: 'Notificación a Cierre de cuenta',
    itNode_notice_pro: 'Notificación a Producto',
    itNode_send_warning: 'Enviar carta de alerta',
    itNode_approve: 'Aprobar',
    itNode_cs_back_visit: 'ATC devuelve a llamar a usuarios',
    itNode_coll_examine: 'Verificación por Cobranza',
    itNode_notice_cs_manage: 'Notificar al administrador de servicio al cliente',
    itNode_replace_collection_user: 'reemplazar colector',
    nodeType: 'Tipo de etapa',
    nodeType_0: 'queja recibida',
    nodeType_1: 'añadir orden de trabajo',
    nodeType_2: 'Notificación',
    nodeType_3: 'Aprobación',
    operater: 'Operador',
    flowStatus: 'Estado',
    flowStatus_0: 'En curso',
    flowStatus_1: 'Cerrado',
    repair: 'reparar',
    repairNotice: 'Arreglar caso de flujo de tarjeta',
    operateTime: 'tiempo operacional',
    approveResult: 'Comentarios de aprobación',
    uploadImage: 'subir imagen',
    upload: 'subir',
    nextApproveRole: 'rol de aprobación subordinado',
    approveRole_0: 'sistema',
    approveRole_1: 'Servicio al Cliente',
    approveRole_2: 'anulación de deuda',
    approveRole_3: 'colección',
    approveRole_4: 'producto',
    approveRole_5: 'administrador de servicio al cliente',
    nextApproveUser: 'aprobador de siguiente nivel',
    hasImage: 'Con imagenes',
    workFlowType: 'tipo de orden de trabajo',
    workFlowType_common: 'común',
    workFlowType_violentCollection: 'colección violenta',
    workFlowType_violentCollectionForAppUser: 'colección violenta',
    workFlowType_balanceAccountForCustomerService: 'problema de equilibrio',
    workFlowType_balanceAccountForCollcetor: 'problema de equilibrio',
    workFlowType_preLoan: 'problema del producto',
    workFlowType_commonForCustomerServiceManage: 'común',
    workFlowType_violentCollectionForCustomerServiceManage: 'colección violenta',
    workFlowType_balanceAccountForCustomerServiceManage: 'problema de equilibrio',
    workFlowType_preLoanForCustomerServiceManage: 'problema del producto',
    baAmount: 'El monto de deuda anulada',
    decision: 'Decisión',
    decision_1: 'Cambiar el asesor de cobranza',
    decision_2: 'El departamento de producto ya conoce',
    decision_3: 'Alivio o anulación de deuda',
    decision_4: 'anulación libre de deuda total',
    decision_5: 'anulación de deuda con revolver el principal',
    decision_6: 'extensión libre',
    decision_7: 'extensión con alivio',
    decision_8: 'Reembolsar',
    decision_9: 'Fraude a los clientes',
    imageLoadingNotice: 'Cargando imagen...',
    operateTypeNotice: 'Seleccione los comentarios de aprobación',
    productRemarkNotice: 'Por favor complete los comentarios',
    unknow: 'ignoto',
    create: 'Crear orden de trabajo',
    WorkOrderCreate: 'Crear orden de trabajo',
    workOrderCreate: 'Crear orden de trabajo',
    custId: 'ID de cliente',
    orderNo: 'número de orden',
    workFlowTypeNotice: 'Seleccione un tipo de orden de trabajo',
    questionV1Notice: 'Seleccione una etiqueta de pregunta',
    approvelUserNotice: 'Seleccione un aprobador subordinado',
    custMobileAndCustEmailNotice: 'Ingrese el número de teléfono móvil o el correo electrónico del cliente',
    appsNotice: 'Por favor elige app',
    complainChannelNotice: 'Seleccione un canal de denuncia',
    processFlows: 'Historial de circulación',
    video: 'video',
    videoPreviewNotice: 'Su navegador no es compatible',
    videoSizeNotice_pre: 'El soporte máximo de video es ',
    videoSizeNotice_suf: 'M',
    videoLoadingNotice: 'carga de video...',
    uploadVideo: 'subir video',
    videoTypeNotice: 'formato de video incorrecto',
    imageSizeNotice_pre: 'La imagen admite hasta ',
    imageSizeNotice_suf: 'M',
    imageTypeNotice: 'error de formato de imagen',
    decisionNotice: 'Seleccione una decisión',
    baAmountNotice: 'Por favor ingrese la El monto de deuda anulada',
    collectionDateNotice: 'Por favor ingrese la fecha de recolección',
    save: 'Guardar',
    level: 'Nivel de denuncia',
    level_1: 'Fraude de usuario',
    level_2: 'Fraude de asesor de cobranza',
    level_3: 'Quejas ordinarias',
    level_4: 'Quejas graves',
    level_5: 'Rechazo a comunicar',
    handleStatus: 'estado de procesamiento',
    handleStatus_0: 'En curso',
    handleStatus_1: 'Terminado',
    satisfaction: 'Encuesta de satisfacción',
    satisfaction_0: 'Ninguna',
    satisfaction_1: 'Insatisfecho',
    satisfaction_2: 'Satisfecho',
    userMobile: 'entrada del usuario móvil',
    name: 'nombre de usuario',
    surname: 'Apellido del usuario',
    appUser: 'Usuario presentado',
    backVisit: 'visita de regreso',
    backVisit_0: 'Sin visita de regreso',
    backVisit_1: 'Visita de regreso',
    connect: 'Conectar',
    connect_0: 'No conectado',
    connect_1: 'Conectado',
    complaintNum: 'número de quejas',
    backVisitNum: 'Número de visitas repetidas',
    connectNum: 'Número de conexiones',
    satisfactionNum: 'Número de satisfactoria',
    connectRate: 'tasa de conexión',
    satisfactionRate: 'Satisfacción',
    coverRate: 'Cobertura',
    exportWithImage: 'Exportar(con imágenes)',
    downloadWithImageNotice: 'Solo se admiten fechas de un solo día',
    settle: 'Ya sea para pagar',
    settle_all: 'todo',
    settle_0: 'No pagado',
    settle_1: 'Ya pagado',
    dataType: 'tipo de datos',
    dataType_commonData: 'datos normales',
    dataType_top10CollctionUserData: 'Datos de los 10 mejores coleccionistas',
    topNum: 'Varios ex',
    lastId: 'Última ID exportada',
    limitNum: 'Volumen de exportación',
    topStatus: 'Situación fijada',
    topStatus_0: 'No fijado',
    topStatus_1: 'Necesita ser fijado',
    exportWithAllcolumns: 'Exportar todos los campos',
    tag: 'etiqueta de modelo',
    tag_1: 'cobro violento',
    tag_2: 'robo de información personal',
    tag_3: 'monto bajo',
    tag_4: 'interés elevado',
    tag_5: 'plazo corto',
    tag_6: 'fraude',
    tag_7: 'revisión lenta',
    tag_8: 'el préstamo no ha llegado',
    tag_9: 'reembolso no mostrado',
    tag_10: 'préstamo rechazado',
    tag_11: 'no recomendado',
    tag_12: 'cobro anticipado',
    tag_13: 'acosar a los contactos',
    tag_14: 'Error de aplicación',
    tag_15: 'No se puede pagar',
    tag_16: 'Llegada lenta',
    tag_17: 'no pudo llegar',
    tag_18: 'Eliminar información de la cuenta',
    tag_19: 'Cancelar préstamo',
    tag_0: 'Cantidad récord',
    settleStatus: 'estado del Se pagó por',
    settleStatus_0: 'No se pagó',
    settleStatus_1: 'Se pagó por adelantado',
    settleStatus_2: 'Se pagó normalmente',
    settleStatus_3: 'Se pagó por atrasado',
    settleStatus_4: 'Se pagó por prolongado',
    settleStatus_5: 'Se pagó por atrasado y prolongado',
    settleStatus_6: 'Se pagó anormalmente',
    settleStatus_no: 'No distingue entre',
    source: 'Fuentes de datos',
    source_1: 'Entrada a la APP de atención al cliente',
    source_2: 'entrada de fondo web',
    source_3: 'entrada de página H5',
    source_4: 'Entrada de servicio al cliente front-end',
    approvalStatus: 'Estado de aprobación',
    'approvalStatus_-1': 'no presentado',
    approvalStatus_0: 'En revisión de la máquina',
    approvalStatus_1: 'Revisión de la máquina rechazada',
    approvalStatus_2: 'Bajo aprobación manual',
    approvalStatus_3: 'Rechazo manual',
    approvalStatus_4: 'Aprobado',
    approvalStatus_5: 'Cancelar',
    approvalStatus_6: 'Préstamo Dawang aprobado',
    aiType: 'tipo de IA',
    aiType_0: 'incógnita',
    aiType_1: 'queja',
    aiType_2: 'consultar',
    resolveStatus: 'Estado de resolución',
    resolveStatus_0: 'no resuelto',
    resolveStatus_1: 'resuelto',
    frontSource: 'Página de origen del front-end',
    frontSource_1: 'Página de inicio sin iniciar sesión',
    frontSource_2: 'Página de inicio iniciada sesión',
    frontSource_3: 'Página de inicio de múltiples productos iniciada',
    frontSource_4: 'mi página',
    frontSource_5: 'info1',
    frontSource_6: 'info2',
    frontSource_7: 'info3',
    frontSource_8: 'info4',
    frontSource_9: 'info5',
    frontSource_10: 'Página de selección de monto',
    frontSource_11: 'bajo revisión',
    frontSource_12: 'Préstamos',
    frontSource_13: 'Préstamo fallido',
    frontSource_14: 'Rechazo de revisión',
    frontSource_15: 'Página de tarjeta de préstamo fallido y revinculación',
    frontSource_16: 'A reembolsar',
    frontSource_17: 'Pago vencido',
    frontSource_18: 'Página de pedido',
    frontSource_19: 'Página de reclamación de cobranza',
    orderType: 'Tipo de orden',
    orderType_0: 'Orden de período único',
    orderType_1: 'Orden de varios períodos',
    dictTextCn: 'chino',
    dictTextEn: 'inglés',
    dictTextEs: 'español',
    dictTextFr: 'francés',
    installmentSettleStatus_0: 'Sin resolver',
    installmentSettleStatus_1: 'Resolvedo',
    orderUnRepayAmount: 'Monto total impago',
    curCapital: 'Principal actual',
    curRepayAmount: 'Cantidad a reembolsar en el período actual',
    curOrderUnRepayAmount: 'Monto total impago en el período actual',
    toFinance: 'Para financiar',
    toFinance_all: 'Todos',
    toFinance_0: 'No',
    toFinance_1: 'Sí',
    handleUser: 'Manejar usuario',
    handleTime: 'Manejar el tiempo',
    subject: 'temática',
    information: 'contenido',
  },
  fr: {
    id: 'ID',
    country: 'nation',
    createTime: 'temps de construction',
    custMobile: 'Numéro de portable du client',
    custEmail: 'Email client',
    app: 'package d\'application',
    status: 'État du billet',
    status1: 'non transformé',
    status2: 'traitement terminé',
    type: 'Type de billet',
    firstLevelQuestion: 'Etiquette de niveau 1',
    secondLevelQuestion: 'étiquette secondaire',
    complainChannel: 'Canal de réclamation client',
    custAppeal: 'Demandes des clients',
    attachmentCode: 'annexe',
    attachment: 'annexe',
    collectionDate: 'date de collecte',
    collectionUser: 'Collecter des utilisateurs',
    collectionMobile: 'Numéro de téléphone de collecte',
    remark: 'Remarque',
    action: 'fonctionner',
    custQueryData: 'Mobile/Courriel',
    createdTime: 'temps de creation',
    days:'limite de temps',
    workOrderDetail: 'Détails du billet',
    status_0: 'Nouveau ajouté',
    status_1: 'Finie',
    status_2: 'Traitement de service client',
    status_3: 'Traitement des comptes d\'équilibre',
    status_4: 'Traitement de le recouvrement',
    status_5: 'Traitement des produits en cours',
    status_6: 'Traitement du système en cours',
    status_7: 'Administrateur du service client en cours',
    type_1: 'plainte',
    type_2: 'consulter',
    type_0: 'inconnu',
    firstLevelQuestion_1: 'Problème d\'application',
    firstLevelQuestion_2: 'collection',
    firstLevelQuestion_3: 'afficher',
    firstLevelQuestion_4: 'code PIN',
    firstLevelQuestion_5: 'prêt',
    firstLevelQuestion_6: 'remboursement',
    firstLevelQuestion_7: 'rouler',
    firstLevelQuestion_8: 'Remboursement',
    secondLevelQuestion_1_1: 'Fonction produit BOGUE',
    secondLevelQuestion_1_2: 'problèmes de localisation',
    secondLevelQuestion_1_3: 'Comment consulter',
    secondLevelQuestion_1_4: 'Modifier les informations personnelles',
    secondLevelQuestion_1_5: 'Consulter l\'adresse de téléchargement',
    secondLevelQuestion_2_1: 'collecte violente',
    secondLevelQuestion_2_2: 'escroquerie au collectionneur',
    secondLevelQuestion_3_1: 'fraude publicitaire',
    secondLevelQuestion_4_1: 'Supprimer le compte',
    secondLevelQuestion_5_1: 'taux d\'intérêt',
    secondLevelQuestion_5_2: 'Montant du prêt',
    secondLevelQuestion_5_3: 'cycle de remboursement',
    secondLevelQuestion_5_4: 'Heure d\'arrivée',
    secondLevelQuestion_5_5: 'refus de prêt',
    secondLevelQuestion_5_6: 'annulation de prêt',
    secondLevelQuestion_6_1: 'Le remboursement n\'a pas pris effet/le problème système ne peut pas rembourser le paiement/la limite de transfert de paiement ne peut pas être remboursée/remboursements multiples/retenue',
    secondLevelQuestion_6_2: 'Consultation sur les modalités de remboursement du prêt/consultation sur les renseignements sur le crédit',
    secondLevelQuestion_7_1: 'Un paiement prolongé est entendu comme un paiement partiel / un paiement prolongé a été payé mais n\'est pas effectif',
    secondLevelQuestion_7_2: 'Renseignez-vous sur les paiements de roulement',
    secondLevelQuestion_8_1: 'Demande à être remboursé',
    secondLevelQuestion_8_2: 'Enquête sur l\'heure d\'arrivée du remboursement',
    complainChannel_1: 'whatsapp',
    complainChannel_2: 'Téléphone',
    complainChannel_3: 'email',
    complainChannel_4: 'entreprise de licence de crisp',
    complainChannel_5: 'régulateur',
    complainChannel_6: 'institution de paiement',
    complainChannel_7: 'court',
    complainChannel_8: 'Bureau du procureur',
    complainChannel_9: 'Hotline de recouvrement',
    complainChannel_10: 'Soumissions des utilisateurs',
    custAppeal_1: 'approbation rapide',
    custAppeal_2: 'Remboursement',
    custAppeal_3: 'remboursement normal',
    custAppeal_4: 'limite_de_relèvement',
    custAppeal_5: 'arrivée de l’argent rapide',
    custAppeal_6: 'utilisation de l’app en douceur',
    custAppeal_7: 'réduction',
    custAppeal_8: 'Changement du statut de la commande',
    custAppeal_9: 'taux réduit',
    custAppeal_10: 'extension du délai de remboursement',
    custAppeal_11: 'remboursement différé',
    custAppeal_12: 'Annuler l\'ordre de prêt',
    custAppeal_13: 'autre',
    canPass_1: 'oui',
    canPass_2: 'non',
    item: 'projet',
    value: 'valeur',
    lastTwoWeekValue: 'il y a deux semaines',
    lastWeekValue: 'la semaine dernière',
    rate: 'Proportion',
    dateLabel: 'étiquette de date',
    reportType: 'taper',
    totalCount: 'Nombre total d\'ordres de travail',
    complaint: 'complainte',
    consult: 'consultation',
    reportType_1: 'quotidien',
    reportType_2: 'hebdomadaire',

    dictType: 'Sorte de dictionnaire',
    dictKey: 'clé du dictionnaire',
    dictValue: 'valeur du dictionnaire',
    dictStatus: 'état du dictionnaire',
    dictSort: 'tri du dictionnaire',
    operate: 'fonctionner',
    dictStatus_0: 'activer',
    dictStatus_1: 'désactivé',

    version: 'numéro de version',
    versionContent: 'contenu de la version',
    forceUpdate: 'Est-ce plus fort',
    downloadUrl: 'chemin de téléchargement',
    forceUpdate_0: 'pas de mise à jour obligatoire',
    forceUpdate_1: 'mise à jour forcée',
    updateTime: 'temps de mise à jour',
    proportion: 'Proportion',
    account: 'compte service client',
    questionV1: 'étiquette de question',
    inputOptRemark: 'Veuillez entrer des notes d\'opération',
    optRemarkMustNotNull: "Les notes d'opération ne peuvent pas être vides",
    optRemark: 'Remarques sur le fonctionnement',
    questionV1_undefined: ' ',
    questionV1_0: ' ',
    questionV1_1: 'BUG du prêt',
    questionV1_2: 'bug de remboursement',
    questionV1_3: 'OTP',
    questionV1_4: 'Recouvrement violent',
    questionV1_5: 'fraude du personnel de recouvrement',
    questionV1_6: 'questions sur la publicité',
    questionV1_7: 'Temps de révision trop long',
    questionV1_8: 'Mettre trop de temps à arriver',
    questionV1_9: 'refus du prêt',
    questionV1_10: 'prêt annulé',
    questionV1_11: 'remboursement non effectif',
    questionV1_12: 'remboursement répétitif',
    questionV1_13: 'déductions',
    questionV1_14: 'crédit',
    questionV1_15: 'l’ajournement est considéré comme un paiement partiel',
    questionV1_16: 'Modification des informations du compte',
    days_all: 'tous',
    days_yesterday: 'hier',
    days_today: 'aujourd\'hui',
    days_last7days: 'sept derniers jours',
    days_last1month: 'le mois dernier',
    days_other: 'autre',
    approve: 'Approbation',
    operateType: 'Type d\'opération',
    operateType_0: 'rejetée',
    operateType_1: 'approuvée',
    operateType_2: 'collecte de transfert',
    operateType_3: 'matériaux additionnels',
    processFlow: 'flux de travail',
    nodeId: 'nœud',
    businessNode: 'nœud de travail',
    businessNode_cs_receive_complaint: 'Le service client a reçu une plainte',
    businessNode_coll_receive_complaint: 'Plainte d\'un client trouvée par le recouvrement',
    businessNode_cs_add_work_order: 'Le service clientèle a saisi un ordre de travail',
    businessNode_coll_add_work_order: 'Le recouvrement a saisi un ordre de travail',
    businessNode_notice_coll: 'Notification système pour le recouvrement',
    businessNode_notice_ba: 'Notification système pour le groupe de comptes d\'équilibr',
    businessNode_notice_cs: 'Notification système pour le service clientèle',
    businessNode_notice_pro: 'Notifications système pour le produit',
    businessNode_notice_cs_send_warning: 'Notification système au service clientèle pour l\'envoi de lettres d\'avertissement',
    businessNode_cs_back_visit: 'Rappel du service clientèle',
    businessNode_ba_approve: 'Examen du bon de travail par l\'équipe de comptes d\'équilibre',
    businessNode_coll_examine_coll_process: 'Le superviseur du recouvrement vérifie le processus de recouvrement',
    businessNode_pro_handle_problem: 'Problème de modification de produit',
    businessNode_notice_cs_manage: 'Le système informe l\'administrateur du service client',
    businessNode_cs_manage_replace_collection_user: 'L\'administrateur du service client remplace le collecteur',
    itNode: 'nœud it',
    itNode_receive_complaint: 'Plainte reçue',
    itNode_add_work_order: 'Ajouter un ordre de travail',
    itNode_notice_coll: 'Notifier le recouvrement',
    itNode_notice_cs: 'Notifier le service clientèle',
    itNode_notice_ba: 'Notifier le groupe de fermeture',
    itNode_notice_pro: 'Notifier le produit',
    itNode_send_warning: 'Envoyer une lettre d\'avertissement',
    itNode_approve: 'Approuver',
    itNode_cs_back_visit: 'Rappel du service clientèle',
    itNode_coll_examine: 'Vérification du recouvrement',
    itNode_notice_cs_manage: 'Informer l\'administrateur du service client',
    itNode_replace_collection_user: 'remplacer le collecteur',
    nodeType: 'Type de nœud',
    nodeType_0: 'Plainte reçue',
    nodeType_1: 'Ajouter un ordre de travail',
    nodeType_2: 'Notification',
    nodeType_3: 'Approbation',
    operater: 'opérateur',
    flowStatus: 'Statut',
    flowStatus_0: 'En cours',
    flowStatus_1: 'Finie',
    repair: 'réparation',
    repairNotice: 'Fixer le boîtier de flux de carte',
    operateTime: 'temps de fonctionnement',
    approveResult: 'Commentaires d\'approbation',
    uploadImage: 'télécharger une image',
    upload: 'télécharger',
    nextApproveRole: 'rôle d\'approbation subordonné',
    approveRole_0: 'système',
    approveRole_1: 'service Clients',
    approveRole_2: 'annulation de la dette',
    approveRole_3: 'collection',
    approveRole_4: 'produit',
    approveRole_5: 'Administrateur service client',
    nextApproveUser: 'Approbateur de niveau suivant',
    hasImage: 'Avec des photos',
    workFlowType: 'Type d\'ordre d\'exécution',
    workFlowType_common: 'normal',
    workFlowType_violentCollection: 'collecte violente',
    workFlowType_violentCollectionForAppUser: 'collecte violente',
    workFlowType_balanceAccountForCustomerService: 'problème d\'équilibre',
    workFlowType_balanceAccountForCollcetor: 'problème d\'équilibre',
    workFlowType_preLoan: 'problème de produit',
    workFlowType_commonForCustomerServiceManage: 'normal',
    workFlowType_violentCollectionForCustomerServiceManage: 'collecte violente',
    workFlowType_balanceAccountForCustomerServiceManage: 'problème d\'équilibre',
    workFlowType_preLoanForCustomerServiceManage: 'problème de produit',
    baAmount: 'montant de la dette annulée',
    decision: 'décision',
    decision_1: 'Changer de collecteur',
    decision_2: 'département des produits est déjà au courant',
    decision_3: 'allégement ou annulation de la dette',
    decision_4: 'annulation totale de la dette',
    decision_5: 'annulation de la dette avec remboursement du principal',
    decision_6: 'prolongation gratuite',
    decision_7: 'allégement ou annulation de la prolongation',
    decision_8: 'remboursement',
    decision_9: 'fraude du client',
    imageLoadingNotice: 'téléchargement d\'image...',
    operateTypeNotice: 'Veuillez sélectionner les commentaires d\'approbation',
    productRemarkNotice: 'Merci de remplir les remarques',
    unknow: 'inconnu',
    create: 'Créer un bon de travail',
    WorkOrderCreate: 'Créer un bon de travail',
    workOrderCreate: 'Créer un bon de travail',
    custId: 'ID client',
    orderNo: 'numéro de commande',
    workFlowTypeNotice: 'Veuillez sélectionner un type de bon de travail',
    questionV1Notice: 'Veuillez sélectionner un libellé de question',
    approvelUserNotice: 'Veuillez sélectionner un approbateur subordonné',
    custMobileAndCustEmailNotice: 'Veuillez entrer le numéro de téléphone portable ou l\'e-mail du client',
    appsNotice: 'Veuillez sélectionner une application',
    complainChannelNotice: 'Veuillez sélectionner un canal de plainte',
    processFlows: 'Historique de diffusion',
    video: 'vidéo',
    videoPreviewNotice: 'Votre navigateur ne prend pas en charge',
    videoSizeNotice_pre: 'La prise en charge vidéo maximale est de ',
    videoSizeNotice_suf: 'M',
    videoLoadingNotice: 'téléchargement de vidéo...',
    uploadVideo: 'Télécharger une video',
    videoTypeNotice: 'mauvais format vidéo',
    imageSizeNotice_pre: 'L\'image prend en charge jusqu\'à ',
    imageSizeNotice_suf: 'M',
    imageTypeNotice: 'erreur de format d\'image',
    decisionNotice: 'Veuillez sélectionner une décision',
    baAmountNotice: 'Veuillez entrer la montant de la dette annulée',
    collectionDateNotice: 'Veuillez entrer la date de collecte',
    save: 'sauver',
    level: 'Niveau de plainte',
    level_1: 'Fraude des utilisateurs',
    level_2: 'fraude des collectionneurs',
    level_3: 'Plaintes générales',
    level_4: 'plainte sérieuse',
    level_5: 'refuser de communiquer',
    handleStatus: 'Statut de traitement',
    handleStatus_0: 'en cours',
    handleStatus_1: 'complété',
    satisfaction: 'Satisfaction',
    satisfaction_0: 'Non',
    satisfaction_1: 'Pas satisfait',
    satisfaction_2: 'satisfaire',
    userMobile: 'entrée utilisateur mobile',
    name: 'nom d\'utilisateur',
    surname: 'Nom de l\'utilisateur',
    appUser: 'Soumis par l\'utilisateur',
    backVisit: 'visite de retour',
    backVisit_0: 'Pas de visite de retour',
    backVisit_1: 'Visite retournée',
    connect: 'Connecter',
    connect_0: 'Pas connecté',
    connect_1: 'Connecté',
    complaintNum: 'nombre de plaintes',
    backVisitNum: 'Nombre de visites répétées',
    connectNum: 'Nombre de connexions',
    satisfactionNum: 'Nombre de satisfaisante',
    connectRate: 'taux de connexion',
    satisfactionRate: 'Satisfaction',
    coverRate: 'Couverture',
    exportWithImage: 'Exporter(avec images)',
    downloadWithImageNotice: 'Seules les dates d\'un seul jour sont prises en charge',
    settle: 'S\'il faut payer',
    settle_all: 'tous',
    settle_0: 'Non payé',
    settle_1: 'Déjà payé',
    dataType: 'type de données',
    dataType_commonData: 'Données normales',
    dataType_top10CollctionUserData: 'Données des 10 meilleurs collectionneurs',
    topNum: 'Plusieurs anciens',
    lastId: 'Dernier identifiant exporté',
    limitNum: 'Volume d\'exportation',
    topStatus: 'Situation épinglée',
    topStatus_0: 'Non épinglé',
    topStatus_1: 'Doit être épinglé',
    exportWithAllcolumns: 'Exporter tous les champs',
    tag: 'étiquette de modèle',
    tag_1: 'recouvrement violent',
    tag_2: 'vol d\'informations personnelles',
    tag_3: 'montant du prêt bas',
    tag_4: 'taux d\'intérêt élevé',
    tag_5: 'délai du prêt court',
    tag_6: 'arnaqueur',
    tag_7: 'durée d\'examen longue',
    tag_8: 'le prêt n\'est pas arrivé',
    tag_9: 'remboursement non présenté',
    tag_10: 'prêt refusé',
    tag_11: 'non recommandé',
    tag_12: 'revouvrement précoce',
    tag_13: 'harcèlement au répertoire',
    tag_14: 'Bogue de l\'application',
    tag_15: 'Impossible de rembourser',
    tag_16: 'Arrivée lente',
    tag_17: 'Impossible d\'arriver',
    tag_18: 'Supprimer les informations du compte',
    tag_19: 'Annuler le prêt',
    tag_0: 'Quantité record',
    settleStatus: 'Statut liquidé',
    settleStatus_0: 'Non liquidé',
    settleStatus_1: 'Liquidé à l\'avance',
    settleStatus_2: 'Liquidé normalement',
    settleStatus_3: 'Liquidé en retard',
    settleStatus_4: 'Liquidé en prolongation',
    settleStatus_5: 'Liquidé de prolongation en retard',
    settleStatus_6: 'Liquidé anormalement',
    settleStatus_no: 'Ne fait pas de distinction entre',
    source: 'Les sources de données',
    source_1: 'Entrée de l\'application du service client',
    source_2: 'entrée d\'arrière-plan Web',
    source_3: 'Entrée de page H5',
    source_4: 'Entrée du service client frontal',
    approvalStatus: 'Statut approuvé',
    'approvalStatus_-1': 'non soumis',
    approvedStatus_0: 'En cours de révision de la machine',
    approvedStatus_1: 'Vérification automatique rejetée',
    approvedStatus_2: 'Approbation manuelle en cours',
    approvedStatus_3: 'Rejet manuel',
    approvedStatus_4: 'Approbation réussie',
    approvalStatus_5: 'Annuler',
    approvalStatus_6: 'Prêt Dawang approuvé',
    aiType: 'Type d\'IA',
    aiType_0: 'inconnu',
    aiType_1: 'Plainte',
    aiType_2: 'Consultation',
    solveStatus: 'état de la résolution',
    solveStatus_0: 'Non résolu',
    solveStatus_1: 'Résolu',
    frontSource: 'page source frontale',
    frontSource_1: 'Page d\'accueil non connectée',
    frontSource_2: 'Page d\'accueil connectée',
    frontSource_3: 'Page d\'accueil multi-produits connecté',
    frontSource_4: 'Ma page',
    frontSource_5: 'info1',
    frontSource_6: 'info2',
    frontSource_7: 'info3',
    frontSource_8: 'info4',
    frontSource_9: 'info5',
    frontSource_10: 'Page de sélection du montant',
    frontSource_11: 'en cours de révision',
    frontSource_12: 'Prêt',
    frontSource_13: 'Le prêt a échoué',
    frontSource_14: 'Rejet de l\'avis',
    frontSource_15: 'Échec du prêt et page de carte de ré-engagement',
    frontSource_16: 'A rembourser',
    frontSource_17: 'Remboursement en retard',
    frontSource_18: 'Page de commande',
    frontSource_19: 'Page de réclamation de recouvrement',
    orderType: 'Type de commande',
    orderType_0: 'Commande sur une seule période',
    orderType_1: 'Commande multi-périodes',
    dictTextCn: 'Chinois',
    dictTextEn: 'anglais',
    dictTextEs: 'Espagnol',
    dictTextFr: 'Français',
    installmentSettleStatus_0: 'Non réglé',
    installmentSettleStatus_1: 'Réglé',
    orderUnRepayAmount: 'Montant total impayé',
    curCapital: 'Principal actuel',
    curRepayAmount: 'Montant à rembourser dans la période en cours',
    curOrderUnRepayAmount: 'Montant total impayé dans la période en cours',
    toFinance: 'To Finance',
    toFinance_all: 'Tous',
    toFinance_0: 'Non',
    toFinance_1: 'Oui',
    handleUser: 'Gérer l\'utilisateur',
    handleTime: 'Temps de gestion',
    subject: 'thème',
    information: 'contenu',
  }
}