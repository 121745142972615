import moment from 'moment'
import store from "@/store";
export function useMock() {
  return Number(store.state.user.userId) === 10
}

const today = new Date()
/*
excel 公示
="{"&A$12&":moment(today).add(-"&ROW()-2&", 'days').format('YYYY-MM-DD'),"&B$12&":"&B2&","&C$12&":"&C2&","&D$12&":"&D2&","&E$12&":"&E2&","&F$12&":"&F2&","&G$12&":"&G2&","&H$12&":"&H2&","&I$12&":"&I2&","&J$12&":"&J2&","&K$12&":"&K2&","&L$12&":"&L2&","&M$12&":"&M2&","&N$12&":"&N2&","&O$12&":"&O2&"},"
curDate	repayCurCount	preCount	repayNormalCount	extendCount	repayOverCount	overExtendCount	overCount	firstOverDueRate	aoverDueRate	perRepayRate	repayNormalRate	extendCountRate	repayOverCountRate	overExtendCountRate
*/

const dailyData = [{
  curDate: moment(today).format('YYYY-MM-DD'),
  repayCurCount: 1211,
  preCount: 323,
  repayNormalCount: 470,
  extendCount: 58,
  repayOverCount: 247,
  overExtendCount: 0,
  overCount: 360,
  firstOverDueRate: 0.2976,
  aoverDueRate: 0.0932,
  perRepayRate: 0.2667,
  repayNormalRate: 0.3881,
  extendCountRate: 0.0479,
  repayOverCountRate: 0.204,
  overExtendCountRate: 0
},
  {
    curDate: moment(today).add(-1, 'days').format('YYYY-MM-DD'),
    repayCurCount: 1136,
    preCount: 313,
    repayNormalCount: 434,
    extendCount: 41,
    repayOverCount: 233,
    overExtendCount: 0,
    overCount: 348,
    firstOverDueRate: 0.3069,
    aoverDueRate: 0.1006,
    perRepayRate: 0.2755,
    repayNormalRate: 0.382,
    extendCountRate: 0.0361,
    repayOverCountRate: 0.2051,
    overExtendCountRate: 0
  },
  {
    curDate: moment(today).add(-2, 'days').format('YYYY-MM-DD'),
    repayCurCount: 1224,
    preCount: 350,
    repayNormalCount: 449,
    extendCount: 47,
    repayOverCount: 275,
    overExtendCount: 0,
    overCount: 378,
    firstOverDueRate: 0.3095,
    aoverDueRate: 0.0836,
    perRepayRate: 0.2859,
    repayNormalRate: 0.3668,
    extendCountRate: 0.0384,
    repayOverCountRate: 0.2247,
    overExtendCountRate: 0
  },
  {
    curDate: moment(today).add(-3, 'days').format('YYYY-MM-DD'),
    repayCurCount: 1193,
    preCount: 295,
    repayNormalCount: 509,
    extendCount: 51,
    repayOverCount: 240,
    overExtendCount: 0,
    overCount: 338,
    firstOverDueRate: 0.284,
    aoverDueRate: 0.0813,
    perRepayRate: 0.2473,
    repayNormalRate: 0.4267,
    extendCountRate: 0.0427,
    repayOverCountRate: 0.2012,
    overExtendCountRate: 0
  },
  {
    curDate: moment(today).add(-4, 'days').format('YYYY-MM-DD'),
    repayCurCount: 1148,
    preCount: 307,
    repayNormalCount: 453,
    extendCount: 51,
    repayOverCount: 227,
    overExtendCount: 0,
    overCount: 337,
    firstOverDueRate: 0.2941,
    aoverDueRate: 0.0957,
    perRepayRate: 0.2674,
    repayNormalRate: 0.3946,
    extendCountRate: 0.0444,
    repayOverCountRate: 0.1977,
    overExtendCountRate: 0
  },
  {
    curDate: moment(today).add(-5, 'days').format('YYYY-MM-DD'),
    repayCurCount: 983,
    preCount: 226,
    repayNormalCount: 412,
    extendCount: 48,
    repayOverCount: 197,
    overExtendCount: 0,
    overCount: 297,
    firstOverDueRate: 0.3022,
    aoverDueRate: 0.1013,
    perRepayRate: 0.2299,
    repayNormalRate: 0.4191,
    extendCountRate: 0.0488,
    repayOverCountRate: 0.2004,
    overExtendCountRate: 0
  },
  {
    curDate: moment(today).add(-6, 'days').format('YYYY-MM-DD'),
    repayCurCount: 1026,
    preCount: 296,
    repayNormalCount: 372,
    extendCount: 38,
    repayOverCount: 232,
    overExtendCount: 0,
    overCount: 320,
    firstOverDueRate: 0.3127,
    aoverDueRate: 0.085,
    perRepayRate: 0.2885,
    repayNormalRate: 0.3626,
    extendCountRate: 0.037,
    repayOverCountRate: 0.2261,
    overExtendCountRate: 0
  },
  {
    curDate: moment(today).add(-7, 'days').format('YYYY-MM-DD'),
    repayCurCount: 937,
    preCount: 228,
    repayNormalCount: 389,
    extendCount: 35,
    repayOverCount: 202,
    overExtendCount: 0,
    overCount: 285,
    firstOverDueRate: 0.3051,
    aoverDueRate: 0.0879,
    perRepayRate: 0.2433,
    repayNormalRate: 0.4152,
    extendCountRate: 0.0374,
    repayOverCountRate: 0.2156,
    overExtendCountRate: 0
  },
  {
    curDate: moment(today).add(-8, 'days').format('YYYY-MM-DD'),
    repayCurCount: 985,
    preCount: 236,
    repayNormalCount: 425,
    extendCount: 36,
    repayOverCount: 210,
    overExtendCount: 0,
    overCount: 288,
    firstOverDueRate: 0.2928,
    aoverDueRate: 0.0786,
    perRepayRate: 0.2396,
    repayNormalRate: 0.4315,
    extendCountRate: 0.0365,
    repayOverCountRate: 0.2132,
    overExtendCountRate: 0
  },
  {
    curDate: moment(today).add(-9, 'days').format('YYYY-MM-DD'),
    repayCurCount: 898,
    preCount: 242,
    repayNormalCount: 342,
    extendCount: 35,
    repayOverCount: 208,
    overExtendCount: 0,
    overCount: 279,
    firstOverDueRate: 0.3118,
    aoverDueRate: 0.0787,
    perRepayRate: 0.2695,
    repayNormalRate: 0.3808,
    extendCountRate: 0.039,
    repayOverCountRate: 0.2316,
    overExtendCountRate: 0
  },]

export const mockRepaymentReport = {
  "code": 1000,
  "data": dailyData.map((
    {
      curDate,
      repayCurCount,
      preCount,
      repayNormalCount,
      extendCount,
      repayOverCount,
      overExtendCount,
      overCount,
      firstOverDueRate,
      aoverDueRate,
      perRepayRate,
      repayNormalRate,
      extendCountRate,
      repayOverCountRate,
      overExtendCountRate
    }) => ({
    curDate,
    repayCurCount,
    preCount,
    repayNormalCount,
    extendCount,
    repayOverCount,
    overExtendCount,
    overCount,
    firstOverDueRate: Math.floor(firstOverDueRate * 10000) / 100 + '%',
    aoverDueRate: Math.floor(aoverDueRate * 10000) / 100 + '%',
    perRepayRate: Math.floor(perRepayRate * 10000) / 100 + '%',
    repayNormalRate: Math.floor(repayNormalRate * 10000) / 100 + '%',
    extendCountRate: Math.floor(extendCountRate * 10000) / 100 + '%',
    repayOverCountRate: Math.floor(repayOverCountRate * 10000) / 100 + '%',
    overExtendCountRate: Math.floor(overExtendCountRate * 10000) / 100 + '%'
  })),
  "msg": "request success"
}

function rate(
  {
    accessUser,//审批通过
    countFaceVerified,//活体
    countRegister,//注册
    countCustBank,//银行卡
    countCustExt,//基本信息
    countCustLinkman,//联系人
    loanUser,//放款：
    submitUser,//提交订单
    countCertification//实名
  }) {
  return {
    accessUser,//审批通过
    countFaceVerified,//活体
    countRegister,//注册
    countCustBank,//银行卡
    countCustBankRate: Math.floor(10000 * countCustBank / countCustLinkman) / 100,
    countCertificationRate: Math.floor(10000 * countCertification / countRegister) / 100,
    countCustExt,//基本信息
    countRegisterRate: 100,
    totalRate: Math.floor(10000 * loanUser / countRegister) / 100,
    countCustLinkman,//联系人
    loanUser,//放款：
    submitUser,//提交订单
    loanUserRate: Math.floor(10000 * loanUser / accessUser) / 100,
    countCustLinkmanRate: Math.floor(10000 * countCustLinkman / countCustExt) / 100,
    accessUserRate: Math.floor(10000 * accessUser / submitUser) / 100,
    countCertification,//实名
    countCustExtRate: Math.floor(10000 * countCustExt / countFaceVerified) / 100,
    submitUserRate: Math.floor(10000 * submitUser / countCustBank) / 100,
    countFaceVerifiedRate: Math.floor(10000 * countFaceVerified / countCertification) / 100
  }
}

export const mockGetOverView = {
  "code": 1000,
  "data": {
    "loanTotalAmount": 18287640,//放款本金
    "totalNonOverCount": 11183,//正常
    "countUser": 28625,//客户
    "totalBalAmt": 7460867,//在贷余额
    "totalOverCount": 5542,//逾期
    "totalCount": 65313,//订单
    "firstOverdue": 32.54,//首逾
    "actualOverdueNew": 10.23,//新户
    "totalNonOverAmt": 5674912,//正常
    "firstOverdueNew": 32.47,//新户
    "actualOverdue": 8.41,//实时逾期率
    "totalAmout": 22859550,//总放款额
    "firstOverdueOld": 27.55,//老户
    "actualOverdueOld": 7.36,//老户
    "totalOverAmt": 1785955,//逾期
    "firstOverdueTenList": dailyData.map(({curDate, firstOverDueRate}) => ({
      dataDt: curDate,
      dataValue: Math.floor(firstOverDueRate * 10000) / 100
    }))
  },
  "msg": "request success"
}
export const mockAsRateInfo = {
  "code": 1000,
  "data": rate({
    "accessUser": 28978,//审批通过
    "countFaceVerified": 123368,//活体
    "countRegister": 142500,//注册
    "countCustBank": 120914,//银行卡
    "countCustExt": 122085,//基本信息
    "countCustLinkman": 121938,//联系人
    "loanUser": 28625,//放款：
    "submitUser": 111905,//提交订单
    "countCertification": 127737,//实名
  }),
  "msg": "request success"
}
export const mockLendingInfo = {
  "code": 1000,
  "data": dailyData.map(({curDate, repayCurCount}) => ({dataDt: curDate, dataValue: repayCurCount})),
  "msg": "request success"
}
export const mockRepayInfo = {
  "code": 1000,
  "data": {
    "curRepayCount": 1267,//今日应还
    "curAlRepayCountNew": 0,
    "curRepayCountOld": 0,
    "curRepayCountNew": 0,
    "curAlRepayCountOld": 0,
    "curAlRepayCount": 527//今日已还
  },
  "msg": "request success"
}
export const mockSettleTypeInfo = {
  "code": 1000,
  "data": dailyData.reduce((
    {
      OVERDUE_EXTENSION_SETTLED,
      EARLY_SETTLED,
      SETTLED,
      EXTENSION_SETTLED,
      OVERDUE_SETTLED
    }, {
      preCount,
      repayNormalCount,
      extendCount,
      repayOverCount,
      overExtendCount
    }) => ({
    OVERDUE_EXTENSION_SETTLED: OVERDUE_EXTENSION_SETTLED + overExtendCount,
    EARLY_SETTLED: EARLY_SETTLED + preCount,
    SETTLED: SETTLED + repayNormalCount,
    EXTENSION_SETTLED: EXTENSION_SETTLED + extendCount,
    OVERDUE_SETTLED: OVERDUE_SETTLED + repayOverCount
  }), {
    "OVERDUE_EXTENSION_SETTLED": 0,
    "EARLY_SETTLED": 0,
    "SETTLED": 0,
    "EXTENSION_SETTLED": 0,
    "OVERDUE_SETTLED": 0
  }),
  "msg": "request success"
}