export default {
    zh: {
        title: '客户操作统计',
        performancetitle: '客户操作统计',

        CLICK_ACCOUNT_CODE: '点击获取验证码',
        CLICK_ACCOUNT_LOGIN: '点击登录',
        CLICK_COLUMN_APPLY: '首页点击申请-首贷',
        CLICK_HOME_REAPPLY: '首页点击申请-复贷',
        CLICK_TAB_HOME: '首页-点击TAB首页',
        CLICK_TAB_MY_LOAN: '首页-点击TAB订单',
        CLICK_TAB_ACCOUNT: '首页-点击TAB我的',
        CLICK_RONG360_P_PICTURE_SUBMIT: '点击P卡拍照',
        CLICK_RONG360_A_PICTURE_SUBMIT_1: '点击a卡正面拍照',
        CLICK_RONG360_A_PICTURE_SUBMIT_2: '点击a卡反面拍照',
        CLICK_RONG360_ID_CARD_SUBMIT_1: '身份证页点击提交',
        CLICK_RONG360_ID_CARD_SUBMIT_2: '身份证页回显点击提交',
        CLICK_RONG360_FACE_RECOGNITION_SUBMIT: '人脸识别点击提交',
        CLICK_RONG360_BASIC_DATA_SUBMIT: '基本信息页点击提交',
        CLICK_RONG360_EMERGENCY_CONTACT: '点击获取第一联系人',
        CLICK_RONG360_OTHER_CONTACT: '点击获取第二联系人',
        CLICK_RONG360_CONTACT_INFORMATION_SUBMIT: '联系人页点击提交',
        CLICK_RONG360_BANK_CARD_SUBMIT: '绑卡页点击提交',
        CLICK_RONG360_APPLY: '选择金额期限-首贷',
        CLICK_RONG360_REAPPLY: '选择金额期限-复贷',
        CLICK_RONG360_SUBMIT: '确认借款-首贷',
        CLICK_RONG360_RESUBMIT: '确认借款-复贷',
        CLICK_RONG360_CONTRACT: '申请成功点OK',
        CLICK_VIEW_ORDER: '订单查看详情',
        CLICK_ACCOUNT_NORMAL: '首页点击还款',
        CLICK_MY_LOAN_NORMAL: '点击正常还款',
        ORDER_REPAID: '订单还清',
        CLICK_ACCOUNT_SETTINGS: '点击查看个人信息',
        CLICK_ACCOUNT_BANK: '点击查看银行卡',
        CLICK_RONG360_CHANGE_PICTURE: '点击证照',
        CLICK_RONG360_CHANGE_BASEINFO: '点击修改个人信息',
        CLICK_RONG360_CHANGE_CONTACT: '点击修改联系人信息',
        FIRST_OPEN: '首次打开',
    },
    en: {
        title: 'Approval Management',
        performancetitle: 'Approval Performance',

        CLICK_ACCOUNT_CODE: 'CLICK_ACCOUNT_CODE',
        CLICK_ACCOUNT_LOGIN: 'CLICK_ACCOUNT_LOGIN',
        CLICK_COLUMN_APPLY: 'CLICK_COLUMN_APPLY',
        CLICK_HOME_REAPPLY: 'CLICK_HOME_REAPPLY',
        CLICK_TAB_HOME: 'CLICK_TAB_HOME',
        CLICK_TAB_MY_LOAN: 'CLICK_TAB_MY_LOAN',
        CLICK_TAB_ACCOUNT: 'CLICK_TAB_ACCOUNT',
        CLICK_RONG360_P_PICTURE_SUBMIT: 'CLICK_RONG360_P_PICTURE_SUBMIT',
        CLICK_RONG360_A_PICTURE_SUBMIT_1: 'CLICK_RONG360_A_PICTURE_SUBMIT_1',
        CLICK_RONG360_A_PICTURE_SUBMIT_2: 'CLICK_RONG360_A_PICTURE_SUBMIT_2',
        CLICK_RONG360_ID_CARD_SUBMIT_1: 'CLICK_RONG360_ID_CARD_SUBMIT_1',
        CLICK_RONG360_ID_CARD_SUBMIT_2: 'CLICK_RONG360_ID_CARD_SUBMIT_2',
        CLICK_RONG360_FACE_RECOGNITION_SUBMIT: 'CLICK_RONG360_FACE_RECOGNITION_SUBMIT',
        CLICK_RONG360_BASIC_DATA_SUBMIT: 'CLICK_RONG360_BASIC_DATA_SUBMIT',
        CLICK_RONG360_EMERGENCY_CONTACT: 'CLICK_RONG360_EMERGENCY_CONTACT',
        CLICK_RONG360_OTHER_CONTACT: 'CLICK_RONG360_OTHER_CONTACT',
        CLICK_RONG360_CONTACT_INFORMATION_SUBMIT: 'CLICK_RONG360_CONTACT_INFORMATION_SUBMIT',
        CLICK_RONG360_BANK_CARD_SUBMIT: 'CLICK_RONG360_BANK_CARD_SUBMIT',
        CLICK_RONG360_APPLY: 'CLICK_RONG360_APPLY',
        CLICK_RONG360_REAPPLY: 'CLICK_RONG360_REAPPLY',
        CLICK_RONG360_SUBMIT: 'CLICK_RONG360_SUBMIT',
        CLICK_RONG360_RESUBMIT: 'CLICK_RONG360_RESUBMIT',
        CLICK_RONG360_CONTRACT: 'CLICK_RONG360_CONTRACT',
        CLICK_VIEW_ORDER: 'CLICK_VIEW_ORDER',
        CLICK_ACCOUNT_NORMAL: 'CLICK_ACCOUNT_NORMAL',
        CLICK_MY_LOAN_NORMAL: 'CLICK_MY_LOAN_NORMAL',
        ORDER_REPAID: 'ORDER_REPAID',
        CLICK_ACCOUNT_SETTINGS: 'CLICK_ACCOUNT_SETTINGS',
        CLICK_ACCOUNT_BANK: 'CLICK_ACCOUNT_BANK',
        CLICK_RONG360_CHANGE_PICTURE: 'CLICK_RONG360_CHANGE_PICTURE',
        CLICK_RONG360_CHANGE_BASEINFO: 'CLICK_RONG360_CHANGE_BASEINFO',
        CLICK_RONG360_CHANGE_CONTACT: 'CLICK_RONG360_CHANGE_CONTACT',
        FIRST_OPEN: 'FIRST_OPEN',
    }
}
