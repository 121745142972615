<template>
    <div class="antv-chart-mini">
        <div class="chart-wrapper trend" :style="{ height }">
            <v-chart
                :force-fit="true"
                :height="height"
                :data="data"
                padding="auto">
                <v-tooltip :showTitle="false" />
                <v-smooth-area position="x*y" :tooltip="tooltipConfig"/>
            </v-chart>
        </div>
    </div>
</template>

<script>
export default {
  name: 'MiniArea',
    props: {
        height: {
            type: Number,
            default: 0
        },
        width: {
            type: Number,
            default: 0
        },
        title: {
            type: String,
            default: ''
        },
        data: {
            type: Array,
            default: () => {
                return []
            }
        },
        scale: {
            type: Array,
            default: () => {
                return [{
                    dataKey: 'x',
                    min: 2
                }, {
                    dataKey: 'y',
                    title: '时间',
                    min: 1,
                    max: 22
                }]
            }
        }
    },
    data () {
        return {
            tooltipConfig: ['x*y', (x, y) => {
                y = y + '%';
                return {
                  name: x,
                  value: y
                };
            }]
        }
    },
    mounted () {
    }
}
</script>

<style lang="less" scoped>
  @import "chart";
    .chart-wrapper.trend {
        bottom: -22px;
    }
</style>
