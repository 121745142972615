import { axios } from '@/utils/request'

export function getAllCollectionTask (parameter = {}) {
  return axios({
    url: '/collectionTask/allTask',
    method: 'post',
    data: parameter
  })
}

export function getCollectionUser (parameter = {}) {
  return axios({
    url: '/collectionTask/listCollectionUser',
    method: 'post',
    data: parameter
  })
}

export function modCollectionTaskUser (parameter = {}) {
  return axios({
    url: '/collectionTask/changeUser',
    method: 'post',
    data: parameter
  })
}

export function getMyCollectionTask (parameter = {}) {
  return axios({
    url: '/collectionTask/myTask',
    method: 'post',
    data: parameter
  })
}

export function getHistoryCollectionTask (parameter = {}) {
  return axios({
    url: '/collectionTask/historyTask',
    method: 'post',
    data: parameter
  })
}

export function getCollectionPerformance (parameter = {}) {
  return axios({
    url: '/collectionTask/performance',
    method: 'post',
    data: parameter
  })
}

export function followCollection (parameter = {}) {
  return axios({
    url: '/collectionTask/follow',
    method: 'post',
    data: parameter
  })
}
export function getListUserWithNum (parameter = {}) {
  return axios({
    url: '/collectionTask/listUserWithNum',
    method: 'post',
    data: parameter
  })
}
export function batchChangeUser (parameter = {}) {
  return axios({
    url: '/collectionTask/changeUsers',
    method: 'post',
    data: parameter
  })
}
export function queryBackPerformance (parameter = {}) {
  return axios({
    url: '/collectionTask/backPerformance',
    method: 'post',
    data: parameter
  })
}

export function queryBackPerformanceForAmount (parameter = {}) {
  return axios({
    url: '/collectionTask/backPerformanceForAmount',
    method: 'post',
    data: parameter
  })
}


export function findCollectionModelList (parameter = {}) {
  return axios({
    url: '/statistics/findCollectionModelList',
    method: 'post',
    data: parameter
  })
}

export function queryReductionList (parameter = {}) {
  return axios({
    url: '/collectionLog/reductionList',
    method: 'post',
    data: parameter
  })
}

export function approvelReduction (parameter = {}) {
  return axios({
    url: '/collectionLog/approvelReduction',
    method: 'post',
    data: parameter
  })
}

export function colDataGroupRepaymentIntention (parameter = {}) {
  return axios({
    url: '/collectionTask/colDataGroupRepaymentIntention',
    method: 'post',
    data: parameter
  })
}

export function colDataGroupFollowResult (parameter = {}) {
  return axios({
    url: '/collectionTask/colDataGroupFollowResult',
    method: 'post',
    data: parameter
  })
}

export function colDataGroupRepaymentIntentionAndName (parameter = {}) {
  return axios({
    url: '/collectionTask/colDataGroupRepaymentIntentionAndName',
    method: 'post',
    data: parameter
  })
}

export function colDataGroupFollowResultAndName (parameter = {}) {
  return axios({
    url: '/collectionTask/colDataGroupFollowResultAndName',
    method: 'post',
    data: parameter
  })
}


export function colDataGroupRepaymentIntentionP (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/colDataGroupRepaymentIntentionP',
    method: 'post',
    data: parameter
  })
}

export function colDataGroupFollowResultP (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/colDataGroupFollowResultP',
    method: 'post',
    data: parameter
  })
}

export function colDataGroupRepaymentIntentionAndNameP (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/colDataGroupRepaymentIntentionAndNameP',
    method: 'post',
    data: parameter
  })
}

export function colDataGroupFollowResultAndNameP (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/colDataGroupFollowResultAndNameP',
    method: 'post',
    data: parameter
  })

}

export function queryZeroCollector (parameter = {}) {
  return axios({
    url: '/collectionTask/collection/GuoNaiZeroCollector',
    method: 'post',
    data: parameter
  })
}


export function queryGuoNaiZeroCollector (parameter = {}) {
  return axios({
    url: '/collectionTask/guoNaiZeroCollector',
    method: 'post',
    data: parameter
  })
}

export function getCollectionRank (parameter = {}) {
  return axios({
    url: '/collectionTask/collectionRank',
    method: 'post',
    data: parameter
  })
}

export function collectionCountByHour (parameter = {}) {
  return axios({
    url: '/collectionReplaceTask/collectionCountByHour',
    method: 'post',
    data: parameter
  })
}