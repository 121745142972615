<style>
  @import './style/common.less';
  #app {
    height: 100%;
  }
</style>
<template>
  <a-locale-provider :locale="locale">
    <div id="app">
      <router-view/>
    </div>
  </a-locale-provider>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import enUS from 'ant-design-vue/lib/locale-provider/en_US'
import esES from 'ant-design-vue/lib/locale-provider/es_ES'
import { AppDeviceEnquire } from '@/utils/mixin'
import { mapGetters } from 'vuex';
import moment from 'moment'
import lang from './i18n/lang'
import 'moment/locale/zh-cn'
import 'moment/locale/es'

const EN = 'en-us'
const ZH = 'zh-cn'
const ES = 'es'
// moment.locale(ZH)

export default {
  mixins: [AppDeviceEnquire],
  data () {
    return {
      locale: zhCN
    }
  },
  watch: {
    languageStorage: {
      handler (lang) {
        if (lang == 'en-US') {
          moment.locale(EN);
          this.locale = enUS;
        } else if (lang == 'zh-CN') {
          moment.locale(ZH);
          this.locale = zhCN;
        } else if (lang == 'es') {
          moment.locale(ES);
          this.locale = esES;
        }
      },
      immediate: true,
    }
  },
  computed: {
      ...mapGetters('language', ['languageStorage']),
  },
}
</script>
