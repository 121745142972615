import { axios } from '@/utils/request'
import {mockRepaymentReport, useMock} from "@/api/_mock";

export function getAllOrderList (parameter = {}) {
  return axios({
    url: '/order/allOrderList',
    method: 'post',
    data: parameter
  })
}
export function getOrderDetail (parameter = {}) {
  return axios({
    url: '/order/detail',
    method: 'post',
    data: parameter
  })
}

export function getOrderInfoQryList (parameter = {}) {
  return axios({
    url: '/order/orderInfoQry',
    method: 'post',
    data: parameter
  })
}

export function getLoanOrderExcelNameList (parameter = {}) {
  return axios({
    url: '/platform/order/getLoanOrderExcelNameList',
    method: 'post',
    data: parameter
  })
}

export function getOrderInfoQryDetail (parameter = {}) {
  return axios({
    url: '/order/detail',
    method: 'post',
    data: parameter
  })
}

export function getApplist (parameter = {}) {
  return axios({
    url: '/app/listALLSelect',
    method: 'post',
    data: parameter
  })
}
export function countLoanDataWithDateForApp (parameter = {}) {
  return axios({
    url: '/platform/order/countLoanDataWithDateForApp',
    method: 'post',
    data: parameter
  })
}

export function reborrowCountAll (parameter = {}) {
  return axios({
    url: '/order/reborrowCountAll',
    method: 'post',
    data: parameter
  })
}
export function getRepaymentReportRateReal (parameter = {}) {
  return axios({
    url: '/statistics/repaymentReportRateReal',
    method: 'post',
    data: parameter
  })
}

export function getOrderDetailForCollection (parameter = {}) {
  return axios({
    url: '/order/detailForCollection',
    method: 'post',
    data: parameter
  })
}

export function getRepaymentReportForBeginDate (parameter = {}) {
  return axios({
    url: '/statistics/platform/repaymentReportForBeginDate',
    method: 'post',
    data: parameter
  })
}

export function getLoanOrderList (parameter = {}) {
  return axios({
    url: '/order/loanOrderList',
    method: 'post',
    data: parameter
  })
}
export function getPendingOrderList (parameter = {}) {
  return axios({
    url: '/order/pendingOrderlist',
    method: 'post',
    data: parameter
  })
}
export function getOverdueList (parameter = {}) {
  return axios({
    url: '/order/overdueList',
    method: 'post',
    data: parameter
  })
}
export function getRepayPlanList (parameter = {}) {
  return axios({
    url: '/repayPlan/list',
    method: 'post',
    data: parameter
  })
}
export function getExamineLogList (parameter = {}) {
  return axios({
    url: '/examine/log/list',
    method: 'post',
    data: parameter
  })
}

export function getCollectionLogList (parameter = {}) {
  return axios({
    url: '/collectionLog/list',
    method: 'post',
    data: parameter
  })
}

export function getOrderNumberList(parameter = {}) {
  return axios({
    url: '/orderNumberInfo/selectByOrderInfoId',
    method: 'post',
    data: parameter
  })
}

export function getOrderImageList(parameter = {}) {
  return axios({
    url: '/orderImage/selectByOrderInfoId',
    method: 'post',
    data: parameter
  })
}


export function getAddressBookList (parameter = {}) {
  return axios({
    url: '/customer/addressBook',
    method: 'post',
    data: parameter
  })
}

export function allowLending (parameter = {}) {
  return axios({
    url: '/order/lending',
    method: 'post',
    data: parameter
  })
}

export function getRepayRecordList (parameter = {}) {
  return axios({
    url: '/repayPlan/repayRecordList',
    method: 'post',
    data: parameter
  })
}

export function getRepaymentReport (parameter = {}) {
  if (useMock()) return Promise.resolve(mockRepaymentReport)
  return axios({
    url: '/statistics/repaymentReport',
    method: 'post',
    data: parameter
  })
}

export function downRepaymentReportExcel (parameter = {}) {
  return axios({
    url: '/statistics/repaymentReportExcel',
    method: 'get',
    data: parameter
  })
}

export function paymentsLink (parameter = {}) {
  return axios({
    url: '/order/paymentsLink',
    method: 'post',
    data: parameter
  })
}

export function reductionLateFee (parameter = {}) {
  return axios({
    url: '/order/reductionLateFee',
    method: 'post',
    data: parameter
  })
}

export function createRepayOrderFunc (parameter = {}) {
  return axios({
    url: '/order/createRepayOrder',
    method: 'post',
    data: parameter
  })
}

export function createRepayUrlFunc (parameter = {}) {
  return axios({
    url: '/order/createRepayUrl',
    method: 'post',
    data: parameter
  })
}

export function repayOfflineAll (parameter = {}) {
  return axios({
    url: '/order/repayOfflineAll',
    method: 'post',
    data: parameter
  })
}

export function getPlatformLoanOrderList (parameter = {}) {
  return axios({
    url: '/platform/order/loanOrderList',
    method: 'post',
    data: parameter
  })
}

export function getPlatformLoanOrderListAlpacash (parameter = {}) {
  return axios({
    url: '/platform/order/loanOrderListAlpacash',
    method: 'post',
    data: parameter
  })
}

export function getModifyCollectorOrderList (parameter = {}) {
  return axios({
    url: '/platform/order/modifyCollectorOrderList',
    method: 'post',
    data: parameter
  })
}

export function getPlatformAllOrderList (parameter = {}) {
  return axios({
    url: '/platform/order/allOrderList',
    method: 'post',
    data: parameter
  })
}

export function getPlatformPendingOrderlist (parameter = {}) {
  return axios({
    url: '/platform/order/pendingOrderlist',
    method: 'post',
    data: parameter
  })
}

export function getPlatformOverdueList (parameter = {}) {
  return axios({
    url: '/platform/order/overdueList',
    method: 'post',
    data: parameter
  })
}

export function countPlatformLoanDataWithDate (parameter = {}) {
  return axios({
    url: '/platform/order/countLoanDataWithDate',
    method: 'post',
    data: parameter
  })
}

export function getPlatformRepaymentReport (parameter = {}) {
  if (useMock()) return Promise.resolve(mockRepaymentReport)
  return axios({
    url: '/statistics/platform/repaymentReport',
    method: 'post',
    data: parameter
  })
}

export function getRepaymentReportForAmount (parameter = {}) {
  return axios({
    url: '/statistics/platform/repaymentReportForAmount',
    method: 'post',
    data: parameter
  })
}

export function getRepaymentReportRate (parameter = {}) {
  return axios({
    url: '/statistics/repaymentReportRate',
    method: 'post',
    data: parameter
  })
}

export function getTransformingDataList (parameter = {}) {
  return axios({
    url: '/statistics/transformingDataList',
    method: 'post',
    data: parameter
  })
}


export function extendOffline (parameter = {}) {
  return axios({
    url: '/order/extendOffline',
    method: 'post',
    data: parameter
  })
}

export function modifyCollector (parameter = {}) {
  return axios({
    url: '/order/modifyCollector',
    method: 'post',
    data: parameter
  })
}

export function countLoanDataWithDate (parameter = {}) {
  return axios({
    url: '/order/countLoanDataWithDate',
    method: 'post',
    data: parameter
  })
}

export function reborrowCount (parameter = {}) {
  return axios({
    url: '/order/reborrowCount',
    method: 'post',
    data: parameter
  })
}

export function reborrowCountForSubmit (parameter = {}) {
  return axios({
    url: '/order/reborrowCountBySubmitDate',
    method: 'post',
    data: parameter
  })
}

export function getOverdueReportRate (parameter = {}) {
  if (useMock()) return Promise.resolve(mockRepaymentReport)
  return axios({
    url: '/statistics/overdueReportRate',
    method: 'post',
    data: parameter
  })
}

export function downOverdueReportExcel (parameter = {}) {
  return axios({
    url: '/statistics/overdueReportRateExcel',
    method: 'get',
    data: parameter
  })
}

export function getPlatformPartRepayOrderList (parameter = {}) {
  return axios({
    url: '/platform/order/partRepayList',
    method: 'post',
    data: parameter
  })
}
export function partRepayOfflineAll (parameter = {}) {
  return axios({
    url: '/order/partRepayOfflineAll',
    method: 'post',
    data: parameter
})
}

export function getRefundTradeFlowList (parameter = {}) {
  return axios({
    url: '/order/refundTradeFlowList',
    method: 'post',
    data: parameter
  })
}

export function refundTrade (parameter = {}) {
  return axios({
    url: '/order/refundTrade',
    method: 'post',
    data: parameter
  })
}
export function editOrderStatusForTest (parameter = {}) {
  return axios({
    url: '/order/test/modifyOrderStatus',
    method: 'post',
    data: parameter
  })
}
export function clearOrderForTest (parameter) {
  return axios({
    url: '/order/test/clearOrderData',
    method: 'post',
    data: parameter
  })
}

export function getAppAllCountForDate (parameter = {}) {
  return axios({
    url: '/statistics/appAllCountForDate',
    method: 'post',
    data: parameter
  })
}

export function getAppAllCount (parameter = {}) {
  return axios({
    url: '/statistics/appAllCount',
    method: 'post',
    data: parameter
  })
}

export function getOrderAllCount (parameter = {}) {
  return axios({
    url: '/statistics/orderAllCount',
    method: 'post',
    data: parameter
  })
}

export function getOrderCountByLoanCount (parameter = {}) {
  return axios({
    url: '/order/countOrderByLoanCount',
    method: 'post',
    data: parameter
  })
}
