export default {
  zh: {
    workorder: '工单号',
    collector: '催收员',
    createRepayOrder: '创建支付订单',
    collectLink: '催收还款H5',
    modifyStatus: '修改状态',
    enterAmount: '请输入金额',
    confirmStatus: '确认处理状态',
    wordOrderStatus_0 :'未处理',
    wordOrderStatus_1 :'等待支付公司处理中',
    wordOrderStatus_2 :'支付公司提示凭证已被使用，需要重新上传',
    wordOrderStatus_3 :'无效的凭证，需重新上传',
    wordOrderStatus_4 :'未操作上传凭证且订单已结清',
  },
  en: {
    workorder: 'Number',
    collector: 'collector',
    createRepayOrder: 'Create payment order',
    collectLink: 'Repayment link',
    modifyStatus: 'Modify status',
    enterAmount: 'Please enter the amount',
    confirmStatus: 'Confirm processing status',
    wordOrderStatus_0 :'Not processed',
    wordOrderStatus_1 :'Waiting for processing by the payment company',
    wordOrderStatus_2 :'The payment company prompts that the voucher has been used and needs to be uploaded again.',
    wordOrderStatus_3 :'Invalid certificate, need to re-upload',
    wordOrderStatus_4 :'No operation has been performed to upload the voucher and the order has been settled',
  },
  es: {
    workorder: 'Número',
    collector: 'Asesor',
    createRepayOrder: 'Crear orden de pago',
    collectLink: 'Obtener liga de pago',
    modifyStatus: 'Modificar estado',
    enterAmount: 'Por favor ingrese la cantidad',
    confirmStatus: 'Confirmar el estado del procesamiento',
    wordOrderStatus_0 :'No procesado',
    wordOrderStatus_1 :'Esperando que la empresa de pago procese',
    wordOrderStatus_2 :'Empresa de pago notifica que el comprobante ya se usó y necesita subir de nuevo',
    wordOrderStatus_3 :'Comprobante inválido,necesita subir de nuevo',
    wordOrderStatus_4 :'No procesado y se liquidó el pedido',
  }
}