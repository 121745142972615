export default {
  zh: {
    marketingSMSTemplates: '营销短信模板',
    templateID: '模板ID',
    templateName: '模板名称',
    type: '类型',
    templateContent: '模板内容',
    updateTime: '更新时间',
    operate: '操作',
    edit: '编辑',
    templateType: '模板类型',
    templateRemarks: '模板备注',
    taskName: '任务名称',
    modifyTemplate: '修改模板',
    state: '状态',
    crowds: '人群',
    addTemplate: '添加模板',
    SMSDeliveryTasks: '短信发送任务',
    taskNode: '任务节点',
    addTask: '添加任务',
    sendingMethod: '发送方式',
    sendingTime: '发送时间',
    SMS: '短信',
    voice: '语音',
    typeOfCoupon: '优惠卷',
    everyDay: '每天',
    setTime: '指定',

    sendingTripartition: '发送三方',
    product: '产品',
    modifyTasks: '修改任务',
    taskStatus: '任务状态',
    typeOfSMS: '短信类型',
    SMSTemplates: '短信模板',
    targetSendingAudience: '发送人群',
    all: '全部',
    open: '开启',
    close: '关闭',
    amountRaisingCoupon: '提额券',
    coupon: '抵扣券',
    couponID: '优惠卷ID',
    couponName: '优惠卷名称',
    couponStatus: '优惠卷状态',
    numberOfCoupons: '优惠卷数量',
    numberOfRemainingCoupons: '优惠卷剩余数量',
    couponAmount: '优惠卷金额',
    couponRestrictions: '优惠卷限制',
    couponType: '优惠卷类型',
    couponDescription: '优惠卷描述',
    couponEffectiveDate: '优惠卷生效时间',
    numberOfDaysInEffectForUsers: '用户生效天数',
    addCoupon: '添加优惠卷',
    modifyCoupon: '修改优惠卷',
    manageCouponTask: '优惠卷任务管理',
    node: '节点',
    search: '搜索',
    reset: '重置',
    success: '操作成功',
    sendType: '发送类型',
    oaJexlId: '算法id',
    taskDesc: '任务发放',
    loanDesc: '放款发放',
    loanNoLimitDesc: '任务发放(无限制)',
  },
  en: {
    marketingSMSTemplates: 'Marketing SMS Templates',
    templateID: 'Template ID',
    templateName: 'Template name',
    type: 'Type',
    templateContent: 'Template content',
    updateTime: 'Update time',
    operate: 'Operate',
    edit: 'Edit',
    templateType: 'Template Type',
    templateRemarks: 'Template Remarks',
    taskName: 'Task name',
    modifyTemplate: 'Modify template',
    state: 'State',
    crowds: 'Crowds',
    addTemplate: 'Add Template',
    SMSDeliveryTasks: 'SMS Delivery Tasks',
    taskNode: 'Task node',
    addTask: 'Add Task',
    sendingMethod: 'Sending method',
    sendingTime: 'Sending time',
    SMS: 'SMS',
    voice: 'voice',
    typeOfCoupon: 'coupon',
    everyDay: 'Every Day',
    setTime: 'Set Time',

    sendingTripartition: 'Sending tripartition',
    product: 'Product',
    modifyTasks: 'Modify tasks',
    taskStatus: 'Task status',
    typeOfSMS: 'Type of SMS',
    SMSTemplates: 'SMS Templates',
    targetSendingAudience: 'Target sending audience',
    all: 'all',
    open: 'Open',
    close: 'Close',
    amountRaisingCoupon: 'Amount-raising coupon',
    coupon: 'Coupon',
    couponID: 'Coupon ID',
    couponName: 'Coupon Name',
    couponStatus: 'Coupon Status',
    numberOfCoupons: 'Number of coupons',
    numberOfRemainingCoupons: 'Number of remaining coupons ',
    couponAmount: 'Coupon Amount',
    couponRestrictions: 'Coupon Restrictions',
    couponType: 'Coupon Type',
    couponDescription: 'Coupon Description',
    couponEffectiveDate: 'Coupon Effective Date',
    numberOfDaysInEffectForUsers: 'Number of days in effect for users',
    addCoupon: 'add coupon',
    modifyCoupon: 'Modify coupon',
    manageCouponTask: 'Manage Coupon Task',
    node: 'Node',
    search: 'Search',
    reset: 'Reset',
    success: 'success',
    sendType: 'sending type',
    oaJexlId: '算法id',
    taskDesc: '任务发放',
    loanDesc: '放款发放',
    loanNoLimitDesc: '任务发放(无限制)',
  },
  es:{
    marketingSMSTemplates: 'Plantillas de SMS de marketing',
    templateID: 'ID de la plantilla',
    templateName: 'Nombre de la plantilla',
    type: 'Tipo',
    templateContent: 'Contenido de la plantilla',
    updateTime: 'tiempo de actualización',
    operate: 'Operar',
    edit: 'Editar',
    templateType: 'Tipo de la plantilla',
    templateRemarks: 'Plantilla Observaciones',
    taskName: 'Nombre de la tarea',
    modifyTemplate: 'Modificar plantilla',
    state: 'Estado',
    crowds: 'Grupo',
    addTemplate: 'Añadir plantilla',
    SMSDeliveryTasks: 'Tareas de envío de SMS',
    taskNode: 'nodo de tareas',
    addTask: 'Añadir tareas',
    sendingMethod: 'método de transmisión',
    sendingTime: 'tiempo de transmisión',
    SMS: 'SMS',
    voice: 'voice',
    typeOfCoupon: 'cupón',
    everyDay: 'Every Day',
    setTime: 'Set Time',

    sendingTripartition: 'el tercero de enviar',
    product: 'productos',
    modifyTasks: 'Modificar la tarea',
    taskStatus: 'Estado de la tarea',
    typeOfSMS: 'Tipo de SMS',
    SMSTemplates: 'Plantillas SMS',
    targetSendingAudience: 'grupo destinatario',
    all: 'all',
    open: 'Iniciar',
    close: 'Cerrar',
    amountRaisingCoupon: 'cupón de retirada de efectivo',
    coupon: 'cupón de descuento',
    couponID: 'ID del cupón',
    couponName: 'Nombre del cupón',
    couponStatus: 'Estado del cupón',
    numberOfCoupons: 'cantidad de cupones',
    numberOfRemainingCoupons: 'cantidad de cupones restantes',
    couponAmount: 'Monto del cupón',
    couponRestrictions: 'Restricciones del cupón',
    couponType: 'Tipo del cupón',
    couponDescription: 'Descripción del cupón',
    couponEffectiveDate: 'tiempo de inicio del cupón',
    numberOfDaysInEffectForUsers: 'días de validacion para usuarios',
    addCoupon: 'add cupón',
    modifyCoupon: 'Modificar cupón',
    manageCouponTask: 'Gestión de tareas de cupones',
    node: 'nodo',
    search: 'Buscar',
    reset: 'Reiniciar',
    success: 'success',

    sendType: 'Tipo de envío',
    oaJexlId: '算法id',
    taskDesc: '任务发放',
    loanDesc: '放款发放',
    loanNoLimitDesc: '任务发放(无限制)',
  },
  fr:{
    marketingSMSTemplates: 'Modèles de SMS marketing',
    templateID: 'ID du modèle',
    templateName: 'Nom du modèle',
    type: 'Genre',
    templateContent: 'Contenu du modèle',
    updateTime: 'Temps de mise à jour',
    operate: 'Gréer',
    edit: 'Editer',
    templateType: 'Type de modèle',
    templateRemarks: 'Remarques de modèle',
    taskName: 'Nom de la tâche',
    modifyTemplate: 'Modifier le modèle',
    state: 'Etat',
    crowds: 'Foules',
    addTemplate: 'Ajouter un modèle',
    SMSDeliveryTasks: 'Tâches d\'envoi de SMS',
    taskNode: 'Nœud de tâches',
    addTask: 'Ajouter des tâches',
    sendingMethod: 'Méthode d\'envoi',
    sendingTime: 'Temps de transmission',
    SMS: 'SMS',
    voice: 'voice',
    typeOfCoupon: 'couponnage',
    everyDay: 'Every Day',
    setTime: 'Set Time',

    sendingTripartition: 'Envoi de la tripartition',
    product: 'Products',
    modifyTasks: 'Modifier des tâches',
    taskStatus: 'Etat de la mission',
    typeOfSMS: 'Type de SMS',
    SMSTemplates: 'Modèles de SMS',
    targetSendingAudience: 'L\'audience d\'envoi ciblée',
    all: 'all',
    open: 'Ouvrir',
    close: 'Fermer',
    amountRaisingCoupon: 'Couponnage de l\'augmentation du montant',
    coupon: 'Couponnage de déduction',
    couponID: 'ID du couponnage',
    couponName: 'Nom du couponnage',
    couponStatus: 'Statut de couponnage',
    numberOfCoupons: 'Nombre de couponnage',
    numberOfRemainingCoupons: 'Nombre de couponnage restant',
    couponAmount: 'Montant du coupon',
    couponRestrictions: 'Restrictions de couponnage',
    couponType: 'Type de couponnage',
    couponDescription: 'Description du couponnage',
    couponEffectiveDate: 'Date d\'entrée en vigueur du couponnage',
    numberOfDaysInEffectForUsers: 'Nombre de jours en vigueur pour les utilisateurs',
    addCoupon: 'add couponnage',
    modifyCoupon: 'Modifier le couponnage',
    manageCouponTask: 'Gestion des tâches liées aux couponnages',
    node: 'Nœud',
    search: 'Rechercher',
    reset: 'Réinitialiser',
    success: 'success',
    sendType: 'Type d\'envoi',
    oaJexlId: '算法id',

    taskDesc: '任务发放',
    loanDesc: '放款发放',
    loanNoLimitDesc: '任务发放(无限制)',
  }
}
