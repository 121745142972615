import CONST_REQUEST from '../../constants/request';
import * as api from '../../api/home';
import apiTools from '../../utils/store'
import {getPartConversionRate} from "../../api/home";

const types = {
    ...apiTools.getTypes([
        'conversionRate',
        'lendingCountByDate',
        'repayCount',
        'loanStatusCount',
        'overView',
      'partConversionRate',
    ]),
}

const getDefaultState = () => {
    return {
        ...apiTools.getStates([
            'conversionRate',
            'lendingCountByDate',
            'repayCount',
            'loanStatusCount',
            'overView',
          'partConversionRate',
        ]),
    }
}

const getters = {
        ...apiTools.getGetters([
            'conversionRate',
            'lendingCountByDate',
            'repayCount',
            'loanStatusCount',
            'overView',
          'partConversionRate',
        ])
    }

const buildAction = (apiName) => {
    return ({ commit }, params) => {
        return api[apiName](params).then((res) => {
            if (res.code == CONST_REQUEST.CODE_SUCCESS) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    }
}

const actions = {
        ...apiTools.getActions([
            'getConversionRate',
            'getLendingCountByDate',
            'getRepayCount',
            'getLoanStatusCount',
            'getOverView',
           'getPartConversionRate',
        ], { types, api, CONST_REQUEST }),
        followCollection: buildAction('followCollection'),
        batchChangeUser: buildAction('batchChangeUser'),
    }

const mutations = {
    ...apiTools.getMutations([
        'conversionRate',
        'lendingCountByDate',
        'repayCount',
        'loanStatusCount',
        'overView',
        'partConversionRate',
    ], { types, CONST_REQUEST })
}


export default {
    namespaced: true,
    state: getDefaultState(),
    getters,
    actions,
    mutations
}