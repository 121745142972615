import { axios } from '@/utils/request'

export function getAllCollectionTask (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/allTask',
    method: 'post',
    data: parameter
  })
}

export function getAllTaskLeader (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/allTaskLeader',
    method: 'post',
    data: parameter
  })
}

export function getSysGroup (parameter = {}) {
  return axios({
    url: '/platform/group/collectionTask/sysGroupList',
    method: 'post',
    data: parameter
  })
}

export function getSysGroupByUserId (parameter = {}) {
  return axios({
    url: '/platform/group/collectionTask/sysGroupListByUserId',
    method: 'post',
    data: parameter
  })
}


export function getCollectionUser (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/listCollectionUser',
    method: 'post',
    data: parameter
  })
}

export function modCollectionTaskUser (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/changeUser',
    method: 'post',
    data: parameter
  })
}

export function getMyCollectionTask (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/myTask',
    method: 'post',
    data: parameter
  })
}


export function saveApplyReduction (parameter = {}) {
  return axios({
    url: '/appReduction/saveApplyReduction',
    method: 'post',
    data: parameter
  })
}

export function getHistoryCollectionTask (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/historyTask',
    method: 'post',
    data: parameter
  })
}

export function getCollectionPerformance (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/performance',
    method: 'post',
    data: parameter
  })
}

export function followCollection (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/follow',
    method: 'post',
    data: parameter
  })
}
export function getListUserWithNum (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/listUserWithNum',
    method: 'post',
    data: parameter
  })
}
export function batchChangeUser (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/changeUsers',
    method: 'post',
    data: parameter
  })
}
export function getQueueList (parameter = {}) {
  return axios({
    url: '/platform/queue/list',
    method: 'post',
    data: parameter
  })
}

export function addQueueList (parameter = {}) {
  return axios({
    url: '/platform/queue/add',
    method: 'post',
    data: parameter
  })
}

export function deleteQueueList (parameter = {}) {
  return axios({
    url: '/platform/queue/delete',
    method: 'post',
    data: parameter
  })
}

export function countCollectionTaskDispense (parameter = {}) {
  return axios({
    url: '/statistics/countCollectionTaskDispense',
    method: 'post',
    data: parameter
  })
}

export function CollectionSignInDistribution (parameter = {}) {
  return axios({
    url: '/collectionReplaceTask/signInDistribution',
    method: 'post',
    data: parameter
  })
}

export function getPerformanceForDepartment (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/performanceForDepartment',
    method: 'post',
    data: parameter
  })
}

export function getPerformanceForDepartmentLock (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/getPerformanceForDepartmentLock',
    method: 'post',
    data: parameter
  })
}

export function getPerformanceForGroup (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/performanceForGroup',
    method: 'post',
    data: parameter
  })
}

export function getPerformanceForGroupLock (parameter = {}) {
  return axios({
    url: '/platform/collectionTask/getPerformanceForGroupLock',
    method: 'post',
    data: parameter
  })
}

export function queryAccountList (parameter = {}) {
  return axios({
    url: '/user/queryAccountList',
    method: 'post',
    data: parameter
  })
}

export function getAccountTradeFlowList (parameter = {}) {
  return axios({
    url: '/user/queryAccountTradeFlowList',
    method: 'post',
    data: parameter
  })
}


export function getActualcollectionTaskCount (parameter = {}) {
  return axios({
    url: '/collectionReplaceTask/actualcollectionTaskCount',
    method: 'post',
    data: parameter
  })
}
export function getActualcollectionTaskCountGroup (parameter = {}) {
  return axios({
    url: '/collectionReplaceTask/actualcollectionTaskCountGroup',
    method: 'post',
    data: parameter
  })
}

export function getActualcollectionTaskCountDepartment (parameter = {}) {
  return axios({
    url: '/collectionReplaceTask/actualcollectionTaskCountDepartment',
    method: 'post',
    data: parameter
  })
}
export function getColUserDealCount (parameter = {}) {
  return axios({
    url: '/collectionTask/colUserDealCount',
    method: 'post',
    data: parameter
  })
}

export function getColUserDealCountV2 (parameter = {}) {
  return axios({
    url: '/collectionTask/colUserDealCountV1',
    method: 'post',
    data: parameter
  })
}

export function getCollectUserDetails (parameter = {}) {
  return axios({
    url: '/collectionTask/collectUserDetails',
    method: 'post',
    data: parameter
  })
}

export function getColUserListByGroupId (parameter = {}) {
  return axios({
    url: '/platform/group/collectionTask/sysUserListByGroupId',
    method: 'post',
    data: parameter
  })
}