import { asyncRouterMap, constantRouterMap } from '@/config/router.config';
import { PageView } from '@/layouts'
import lang from '../../i18n/lang'
import I18N from '../../i18n'; 

/**
 * 过滤账户是否拥有某一个权限，并将菜单从加载列表移除
 *
 * @param permission
 * @param route
 * @returns {boolean}
 */
function hasPermission (permission, route) {
  if (route.meta && route.meta.permission) {
    let flag = false
    for (let i = 0, len = permission.length; i < len; i++) {
      flag = route.meta.permission.includes(permission[i])
      if (flag) {
        return true
      }
    }
    return false
  }
  return true
}

/**
 * 单账户多角色时，使用该方法可过滤角色不存在的菜单
 *
 * @param roles
 * @param route
 * @returns {*}
 */
// eslint-disable-next-line
function hasRole(roles, route) {
  if (route.meta && route.meta.roles) {
    return route.meta.roles.includes(roles.id)
  } else {
    return true
  }
}
function filterPath(menus) {
  return (menus || []).reduce((arr, item) => {
    arr.push(item.menuPath)
    if (item.children && item.children.length) {
      arr = arr.concat(filterPath(item.children));
    }
    return arr;
  }, []);
}

function filterAsyncRouter (routerMap, permissions) {
    const accessedRouters = (routerMap || []).filter(route => {
      if (permissions.includes(route.path)) {
        if (route.children && route.children.length) {
          route.children = filterAsyncRouter(route.children, permissions)
        }
        return true
      }
      return false
    })
    return accessedRouters
}

function generator (routerMap) {
  return routerMap.map(item => {
    const { hidden, component, menuIcon: icon, menuPath, parentId } = item || {};
    const currentRouter = {
      // 如果路由设置了 path，则作为默认 path，否则 路由地址 动态拼接生成如 /dashboard/workplace
      path: item.menuPath,
      hidden: !!hidden,
      // 路由名称，建议唯一
      name: item.nameEn || '',
      // 该路由对应页面的 组件 :方案1
      // component: constantRouterComponents[item.component || item.key],
      // 该路由对应页面的 组件 :方案2 (动态加载)
      component: item.component ? () => import(`@/views${item.component}`) : PageView,
      // component: item.component ? () => import(`@/views/operation/QueueList`) : PageView,

      // meta: 页面标题, 菜单图标, 页面权限(供指令权限用，可去掉)
      meta: { title: item.nameCn, enTitle: item.nameEn, icon: icon || undefined }
    }
    // 是否有子菜单，并递归处理
    if (item.children && item.children.length > 0) {
      // Recursion
      currentRouter.children = generator(item.children)
    }
    return currentRouter
  })
}

const permission = {
  state: {
    routers: constantRouterMap,
    addRouters: [],
  },
  mutations: {
    SET_ROUTERS: (state, routers) => {
      state.addRouters = routers
      state.routers = constantRouterMap.concat(routers)
    }
  },
  actions: {
    GenerateRoutes ({ commit }, data) {
      return new Promise(resolve => {
        const { menus } = data
        asyncRouterMap[0].children = generator(menus);
        commit('SET_ROUTERS', asyncRouterMap)
        resolve(asyncRouterMap);
      })
    }
  }
}

export default permission
