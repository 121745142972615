export default {
    zh: {
        title: '数据报表',
        performancetitle: '逾期率报表',
        appSsid: '应用',
        allSettleCount: '应还款笔数',
        firstOverDueRate: '首逾率',
        curDate:"日期",
        T1colbackRate: 'T1实时逾期率',
        T2colbackRate: 'T2实时逾期率',
        T3colbackRate: 'T3实时逾期率',
        T4colbackRate: 'T4实时逾期率',
        T5colbackRate: 'T5实时逾期率',
        T6colbackRate: 'T6实时逾期率',
        T7colbackRate: 'T7实时逾期率',
        T8colbackRate: 'T8实时逾期率',
        T9colbackRate: 'T9实时逾期率',
        T10colbackRate: 'T10实时逾期率',
        T11colbackRate: 'T11实时逾期率',
        T12colbackRate: 'T12实时逾期率',
        T13colbackRate: 'T13实时逾期率',
        T14colbackRate: 'T14实时逾期率',
        T14OverbackRate: 'T14+实时逾期率',
    },
    en: {
        title: 'Approval Management',
        performancetitle: 'Approval Performance',
        appSsid: 'App',
        allSettleCount: 'Number of repayments due',
        firstOverDueRate: 'First over rate',
        curDate:"date",
        T1colbackRate: 'T1 Real time overdue rate',
        T2colbackRate: 'T2 Real time overdue rate',
        T3colbackRate: 'T3 Real time overdue rate',
        T4colbackRate: 'T4 Real time overdue rate',
        T5colbackRate: 'T5 Real time overdue rate',
        T6colbackRate: 'T6 Real time overdue rate',
        T7colbackRate: 'T7 Real time overdue rate',
        T8colbackRate: 'T8 Real time overdue rate',
        T9colbackRate: 'T9 Real time overdue rate',
        T10colbackRate: 'T10 Real time overdue rate',
        T11colbackRate: 'T11 Real time overdue rate',
        T12colbackRate: 'T12 Real time overdue rate',
        T13colbackRate: 'T13 Real time overdue rate',
        T14colbackRate: 'T14 Real time overdue rate',
        T14OverbackRate: 'T14+ Real time overdue rate',
    }
}
