export default {
    zh: {
        title: '审批管理',
        allTitle: '所有审批任务',
        mytitle: '我的审批任务',
        historytitle: '历史审批任务',
        performancetitle: '审批绩效',
        invoke: '调单',

        examinerName: '审批人',
        examineTime: '审批时间',
        approvalTotal: '审批总数',
        approvalNum: '审批通过数',
        ApprovalRejectNum: '审批拒绝数',
        ApprovalCancleNum: '审批取消数',
        approvalRate: '审批通过率',
        overdueNum: '逾期订单数',
        overdueRate: '逾期率',
        q1:"借款人电话是否接通",
        yes:"是",
        noresponse:"无人接听",
        invalidnumber:"无效号码",
        switchoff:"关机",
        reject:"拒绝",
        no:"否",
        q2:"借款人是否确认贷款资料由本人填写",
        q3:"借款人是否能流利说出注册手机号",
        q4:"借款人是否能说出自己生日",
        q5:"借款人对借款期限、金额是否有异议",
        q6:"联系人电话是否接通",
        q7:"联系人是否认识借款人并能说出关系",
    },
    en: {
        title: 'Approval Management',
        allTitle: 'All Approval Tasks',
        mytitle: 'My Approval Tasks',
        historytitle: 'History Approval Tasks',
        performancetitle: 'Approval Performance',
        invoke: 'Invoke Order',

        examinerName: 'Examiner Name',
        examineTime: 'Examine Time',
        approvalTotal: 'Total Approvals',
        approvalNum: 'Approved Number',
        ApprovalRejectNum: 'Approval Reject Number',
        ApprovalCancleNum: 'Approval Cancle Number',
        approvalRate: 'Approved Rate',
        overdueNum: 'Overdue Number',
        overdueRate: 'Overdue Rate',
        q1:"Does the call gets connected to the client?",
        yes:"Yes",
        noresponse:"No Response",
        invalidnumber:"Invalid Number",
        switchoff:"Switch off",
        reject:"Reject",
        no:"No",
        q2:"Does the borrower confirm that all information provided has been filled by themselves?",
        q3:"Is the borrower able to verify his/her registered Mobile number immediately?",
        q4:"Is the borrower able to verify his/her date of birth?",
        q5:"Does the borrower have any complaints on loan duration and loan amount?",
        q6:"Are you able to reach on the alternate contact number?",
        q7:"Does alternate contact know the borrower?",
    }
}
