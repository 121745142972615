import customer from './modules/customer';
import order from './modules/order';
import approval from './modules/approval';
import collection from './modules/collection';
import risk from './modules/risk';
import remind from './modules/remind';
import repayReport from './modules/repayReport';
import repayOverDueReport from './modules/repayOverDueReport';
import platformCollection from './modules/platformCollection';
import custServiceWorkOrder from './modules/custServiceWorkOrder';
import collectorTotalFollow from './modules/collectorTotalFollow';
import oaSystem from './modules/oaSystem';
import teleMarket from "./modules/teleMarket";
import coupon from './modules/coupon';
import repaycert from "./modules/repaycert";

export default {
  code: 'en-US',
  label: 'English',

  error: 'error',
  tips: 'tips',
  reset: 'reset',
  search: 'search',

  searchColAll: 'Consulta',
  searchColCur: 'El día de la consulta',
  searchColHis: 'Historial de consulta',

  check: 'check',
  edit: 'edit',
  more: 'more',
  delete: 'delete',
  enter: 'Please enter',
  select: 'Please select',
  show: 'show',
  hide: 'hide',
  confirm: 'confirm',
  cancel: 'cancel',
  sort: 'sort',
  action: 'Operaciones',
  add: 'Add',
  view: 'Ver',
  success: 'Success',
  fail: 'Fail',
  userName: 'Username',
  signOut: 'Sign out',
  signIn: 'Sign in',
  requestFail: 'Request failed, please try again',
  confirmDelete: 'Confirm to delete?',
  oldPassword: 'Old Password',
  password: 'Password',
  newPassword: 'New Password',
  confirmPassword: 'Confirm Password',
  sex: 'Género',
  name: 'Name',
  birthday: 'Birthday',
  date: 'Date',
  time: 'Time',
  man: 'Male',
  woman: 'Female',
  thirdGender: 'Third Gender',
  email: 'Buzón de correo',
  phone: 'Phone',
  phoneNo: 'Phone Number',
  enable: 'Enable',
  disable: 'Disable',
  status: 'Status',
  remark: 'Remark',
  decLateFee: 'Solicitar la liberación',
  createRepayOrder: 'Create PayOrder',
  createRepayUrl: 'Create PayUrl',
  handle: 'Handle',
  reCreate: 'ReCreate',
  flush: 'Flush',
  createReport: 'Create Report',
  close: 'Close',
  components: {
    total: 'Total',
    items: 'items',
  },

  home: {
    title: 'Home',
    modifyPassword: 'Modify Password',
    userInfoFail: 'Requesting user information failed, please try again',
    signOutInfo: 'Do you really want to log out?',
    userNameTips: 'Please enter user name',
    passwordTips: 'Please enter the password',
    earlyMorning: 'Good morning',
    morning: 'Good morning',
    noon: 'Good noon',
    afternoon: 'Good afternoon',
    evening: 'Good evening',
    welcome: 'welcome',
    welcomeBack: 'welcome back',
  },
  customer: customer.en,
  system: {
    title: 'System Management',
    menuList: {
      menu: 'menu',
      title: 'Menu Management',
      index: 'index',
      menuName: 'name',
      icon: 'menu icon',
      type: 'type',
      superiorMenu: 'Superior Menu',
      menuUrl: 'Menu URL',
      menuComponent: 'Menu Component',
      sort: 'sort',
      action: 'action',
      edit: 'edit',
      delete: 'delete',
      add: 'add',
      mainSub: 'main menu',
      submenu: 'sub menu',
      buttonPermission: 'button/permission',
      nameCn: 'Chinese Name',
      nameEn: 'English Name',
      preMenu: 'previous menu',
      menuCode: 'menu code',
    },
    userList: {
      title: 'User List',
      index: 'index',
      name: 'name',
      phone: 'phone',
      mail: 'mail',
      time: 'Create Time',
      position: 'Department / post',
      role: 'role',
      app: 'application',
      status: 'status',
      action: 'action',
      add: 'add',
      edit: 'edit',
      password: 'password',
      delete: 'delete',
    },
    roleList: {
      title: 'Role List',
      index: 'index',
      type: 'role type',
      code: 'role code',
      description: 'description',
      time: 'create time',
      action: 'action',
      add: 'add',
      edit: 'edit',
      password: 'password',
      delete: 'delete',
      permission: 'permission',
    },
  },
  order: order.en,
  account: {
    accountDetail: 'Account Detail',
  },
  postLoan: {
    title: 'Post-loan Management',
  },
  approval: approval.en,
  oaSystem: oaSystem.en,
  collection: collection.en,
  collectorTotalFollow: collectorTotalFollow.en,
  remind: remind.en,
  risk: risk.en,
  repayReport: repayReport.en,
  repayOverDueReport: repayOverDueReport.en,
  other: {
    title: 'other',
  },
  platformCollection: platformCollection.en,
  custServiceWorkOrder: custServiceWorkOrder.en,
  teleMarket: teleMarket.en,
  coupon: coupon.en,
  repaycert: repaycert.en
};
