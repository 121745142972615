<template>
  <a-breadcrumb class="breadcrumb">
    <a-breadcrumb-item v-for="(item, index) in breadList" :key="item.name">
      <router-link
        v-if="item.name != name && index != 1"
        :to="{ path: item.path === '' ? '/' : item.path }"
      >{{ $t(item.meta.title) }}</router-link>
      <span v-else>{{ languageStorage == lang.zhCN ? item.meta.title : item.meta.enTitle }}</span>
    </a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import router from '../../router'
import lang from '../../i18n/lang/'

export default {
  data () {
    return {
      lang,
      name: '',
      breadList: []
    }
  },
  computed: {
      ...mapGetters('language', ['languageStorage']),
  },
  watch: {
    $route () {
      this.getBreadcrumb()
    },
    languageStorage: {
      handler (lang) {
          this.getBreadcrumb();
      },
      immediate: true,
    }
  },
  created () {
    this.getBreadcrumb()
  },
  methods: {
    ...mapActions(['GenerateRoutes']),

    getBreadcrumb () {
      this.breadList = []
      // this.breadList.push({name: 'index', path: '/dashboard/', meta: {title: '首页'}})

      this.name = this.$route.name;
      this.$route.matched.forEach(item => {
        // item.name !== 'index' && this.breadList.push(item)
        this.breadList.push(item)
      })
    }
  },
}
</script>

<style scoped>
</style>
