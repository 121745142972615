import api from './index'
import { axios } from '@/utils/request'
import {
  useMock,
  mockGetOverView,
  mockAsRateInfo,
  mockLendingInfo,
  mockRepayInfo,
  mockSettleTypeInfo
} from "./_mock";


/**
 * 首页头部四个信息
 * @param  {Object} parameter [description]
 * @return {[type]}           [description]
 */
export function getOverView (parameter = {}) {
  if (useMock()) return Promise.resolve(mockGetOverView)
  return axios({
    url: '/statistics/overview',
    method: 'post',
    data: parameter
  })
}

/**
 * 转化率 -- 进度条
 * @param  {Object} parameter [description]
 * @return {[type]}           [description]
 */
export function getConversionRate (parameter = {}) {
  if (useMock()) return Promise.resolve(mockAsRateInfo)
  return axios({
    url: '/statistics/asRateInfo',
    method: 'post',
    data: parameter
  })
}

/**
 * 进十日放款情况 -- 折线图
 * @param  {Object} parameter [description]
 * @return {[type]}           [description]
 */
export function getLendingCountByDate (parameter = {}) {
  if (useMock()) return Promise.resolve(mockLendingInfo)
  return axios({
    url: '/statistics/lendingInfo',
    method: 'post',
    data: parameter
  })
}

/**
 * 当日回款情况 -- 水波图
 * @param  {Object} parameter [description]
 * @return {[type]}           [description]
 */
export function getRepayCount (parameter = {}) {
  if (useMock()) return Promise.resolve(mockRepayInfo)
  return axios({
    url: '/statistics/repayInfo',
    method: 'post',
    data: parameter
  })
}

/**
 * 订单按结清状态分组统计 -- 饼图
 * @param  {Object} parameter [description]
 * @return {[type]}           [description]
 */
export function getLoanStatusCount (parameter = {}) {
  if (useMock()) return Promise.resolve(mockSettleTypeInfo)
  return axios({
    url: '/statistics/settleTypeInfo',
    method: 'post',
    data: parameter
  })
}

export function getPartConversionRate (parameter = {}) {
  if (useMock()) return Promise.resolve(mockAsRateInfo)
  return axios({
    url: '/statistics/asRateInfoPart',
    method: 'post',
    data: parameter
  })
}