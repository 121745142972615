import CONST_REQUEST from '../../constants/request';
import * as api from '../../api/platformCollection';
import apiTools from '../../utils/store'
import {queryAccountTradeFlowList} from "../../api/platformCollection";

const types = {
    ...apiTools.getTypes([
        'listUserWithNum',
    ]),
}

const getDefaultState = () => {
    return {
        ...apiTools.getStates([
            'listUserWithNum',
        ]),
    }
}

const getters = {
        ...apiTools.getGetters([
            'listUserWithNum',
            'accountTradeFlowList',
        ])
    }

const buildAction = (apiName) => {
    return ({ commit }, params) => {
        return api[apiName](params).then((res) => {
            if (res.code == CONST_REQUEST.CODE_SUCCESS) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    }
}

const actions = {
        ...apiTools.getActions([
            'getListUserWithNum',
            'getAccountTradeFlowList',
        ], { types, api, CONST_REQUEST }),
        followCollection: buildAction('followCollection'),
        batchChangeUser: buildAction('batchChangeUser'),
    }

const mutations = {
    ...apiTools.getMutations([
        'listUserWithNum',
    ], { types, CONST_REQUEST })
}


export default {
    namespaced: true,
    state: getDefaultState(),
    getters,
    actions,
    mutations
}