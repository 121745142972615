export default {
    ORDER_STATUS: 'orderstatus', // 订单状态
    BANK: 'bank', // 银行名称
    REALTIONSHIP: 'secrelationship', // 联系人关系
    SEC_RELATIONSHIP: 'secrelationship', // 其他联系人关系
    MARITAL_STATUS: 'maritalstatus', // 婚姻状况
    NUMBER_OF_CHILDREN: 'numberOfChildren',//孩子数量
    EDUCATION: 'education', // 教育程度
    WORK_TYPE: 'worktype', // 工作类型
    ACCMMODATION_TYPE: 'accommodationtype', // 住房类型
    POSITION: 'position', // 职位
    LOAN_STATUS: 'loanstatus', // 放款状态
    OVERDUE_STATUS: 'overduestatus', // 逾期状态
    PAY_METHED: 'paymethed', // 发薪方式
    WORK_YEARS: 'workyears', // 工作年限
    PROFESSION: 'profession', // 工作行业
    DESIGNATION: 'designation', // 工作职务
    ORDER_TYPE: 'ordertype', // 订单类型
    SETTLE_STATUS: 'settlestatus', // 结清状态
    APPROVAL_TASK_STATUS: 'approvetaskstatus', // 任务状态
    APPROVAL_STATUS: 'approvestatus', // 审核状态
    FOLLOW_RESULT: 'followresult', // 跟进结果
    REAPYMENT_INTENTION: 'repaymentintention', // 还款意愿
    COLLECTION_TASK_STATUS: 'collectiontaskstatus', // 催收任务状态
    ALLOW_DIFER_TIME: 'allowdifertime', //允许展期时间
    PRODICT_CONFIG_TYPE: 'prodictconfigtype',
    REPAYMENT_METHOD: 'repaymentmethod',
    LOAN_PURPOSE: 'loanpurpose', //借款用途
    REMIND_TASK_STATUS: 'remindtaskstatus', //借款用途
    MONTHLY_INCOME:'monthlyincom',
    CONTACT_RESULT:'contactresult',//提醒管理-跟进结果
    COLLECTOR_TYPE:'collectortype',//催收类型
    CUST_CONTRACT_CONDITION: 'custcontractcondition', // 跟进结果
    LINKCONDITION: 'linkcondition', // 通讯录和联系人情况
    BANKACCOUNT_TYPE:'bankaccounttype',// 银行卡类型
    BANKNAMELIST:'banknamelist',//催收类型
    BANKLISTF:'banklistf',
    questionType:'questiontype',
    complainType:'complaintype',
    STAGE_TYPE:'stagetype',  // 分期类型
	COLLECTOR_COUNTRY: 'collectorcountry'
}
