import CONST_REQUEST from '../../constants/request';
import * as api from '../../api/customer';
import apiTools from '../../utils/store'

const types = {
    ...apiTools.getTypes([
        'customerList',
        'customerQryList',
        'customerDetail',
        'checkBankCard',
      'addWritelist',
    ]),
}

const getDefaultState = () => {
    return {
        ...apiTools.getStates([
            'customerList',
            'customerQryList',
            'customerDetail',
          'checkBankCard',
          'addWritelist',
        ]),
    }
}

const getters = {
        ...apiTools.getGetters([
            'customerList',
            'customerQryList',
            'customerDetail',
          'checkBankCard',
          'addWritelist',
        ])
    }

const buildAction = (apiName) => {
    return ({ commit }, params) => {
        return api[apiName](params).then((res) => {
            if (res.code == CONST_REQUEST.CODE_SUCCESS) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    }
}

const actions = {
        ...apiTools.getActions([
            'getCustomerList',
            'getCustomerQryList',
            'getCustomerDetail',
            'checkBankCard',
            'addWritelist',



        ], { types, api, CONST_REQUEST }),
    }

const mutations = {
    ...apiTools.getMutations([
        'customerList',
        'customerQryList',
        'customerDetail',
      'checkBankCard',
      'addWritelist',
    ], { types, CONST_REQUEST })
}


export default {
    namespaced: true,
    state: getDefaultState(),
    getters,
    actions,
    mutations
}