import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import notification from 'ant-design-vue/es/notification'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import cookie from './cookie';
import CODE_REQUEST from '@/constants/request'
const getHeaders = () => {
    return {
        token: cookie.get('token'),
        'client-id': '2ef739e2-4593-4108-bbf6-b869ee66bbe7',
    }
}

let webpublic = '';

// 创建 axios 实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL, // api base_url
})

const err = (error) => {
  if (error.response) {
    const data = error.response.data
    const token = cookie.get('token')
    if (error.response.status === 403) {
      notification.error({
        message: 'Forbidden',
        description: data.message
      })
    }
    if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
      notification.error({
        message: 'Unauthorized',
        description: 'Authorization verification failed'
      })
      if (token) {
        store.dispatch('Logout').then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 1500)
        })
      }
    }
  }
  return Promise.reject(error)
}

// request interceptor
service.interceptors.request.use(config => {
  config.headers = {
    ...config.headers,
    ...getHeaders(),
  }
  config.baseURL = process.env.VUE_APP_API_BASE_URL+ '/web' + webpublic
  return config
}, err)

// response interceptor
service.interceptors.response.use((response) => {
  const data = response.data || {};
  if (data.code == CODE_REQUEST.CODE_UNLOGIN) {
      cookie.delete('token');
      store.dispatch('clearUserInfo')
      setTimeout(() => {
        window.location.href = window.location.origin + '/user/login';
      }, 500);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 16)
  }
  return response.data
}, err)

const installer = {
  vm: {},
  install (Vue) {
    Vue.use(VueAxios, service)
  }
}

function changeWebPublic(path){
  webpublic = path;
}

export {
  installer as VueAxios,
  service as axios,
  getHeaders,
  changeWebPublic,
}
