import Vue from 'vue'
import { login, getPermissionsRoutes, logout, modifyPassword } from '@/api/login'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { welcome } from '@/utils/util'
import cookie from '../../utils/cookie';
import CONST_REQUEST from '../../constants/request';

const user = {
  state: {
    token: '',
    name: localStorage.getItem('userName') || '',
    welcome: '',
    userId: localStorage.getItem('userId') || '',
    avatar: localStorage.getItem('avatarUrl') || '',
    roles: [],
    menus: [],
    info: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome,userId }) => {
      localStorage.setItem('userName', name)
      localStorage.setItem('userId', userId)
      state.name = name
      state.welcome = welcome
      state.userId = userId
    },
    SET_AVATAR: (state, avatar) => {
      localStorage.setItem('avatarUrl', avatar)
      state.avatar = avatar
    },
    SET_MENUS: (state, menus) => {
      state.menus = menus
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
        return login(userInfo).then(res => {
          const data = res.data || {};
          if (res.code == CONST_REQUEST.CODE_SUCCESS) {
              cookie.set('token', data.token);
              commit('SET_TOKEN', data.token);
              commit('SET_NAME', { name: data.userName, userId: data.userId, welcome: welcome() })
              // commit('SET_AVATAR', "http://8.140.147.56/static/adv.jpg")
              commit('SET_AVATAR', "https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png")
          } else {
              throw new Error(res.msg)
          }
        }).catch(e => {
          throw e;
        })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return getPermissionsRoutes().then(res => {
          const data = res.data || {};
          if (res.code == CONST_REQUEST.CODE_SUCCESS) {
              commit('SET_MENUS', data.menus || []);
          } else {
              throw new Error(res.msg)
          }
          return res;
        }).catch(e => {
          throw e;
        })
    },

    // 清除用户信息
    clearUserInfo ({ commit }) {
      commit('SET_MENUS', []);
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
    },


    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          cookie.delete('token');
          resolve()
        }).catch(e => {
          throw new Error(e.message);
        }).finally(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          commit('SET_MENUS', [])
          // cookie.delete('token');
        })
      })
    },

    // 登出
    LogoutV1 ({ commit, state }) {
      return new Promise((resolve) => {
        cookie.delete('token');
        resolve()
        commit('SET_TOKEN', '')
        commit('SET_ROLES', [])
        commit('SET_MENUS', [])
      })
    },

    modifyPassword ({ commit }, params) {
        return modifyPassword(params).then((res) => {
            if (res.code == CONST_REQUEST.CODE_SUCCESS) {
                return res
            } else {
                throw new Error(res.msg)
            }
        })
    }

  }
}

export default user
