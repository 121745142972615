export default {
  zh: {
    billDate: '账单日期',
    dataCaliber: '数据口径',
    hierarchyObject: '层级对象',
    departmentName: '部门名称',
    collectionStage: '催收阶段',
    groupName: '小组',
    collectorName: '催收员姓名',
    date: '日期',
    department: '部门',
    orderNum: '分派订单数',
    repaidOrders: '已还订单（含展期）',
    orderAmount: '到期日金额-总额',
    yiHuanAmount: '到期日金额-已还',
    huanKuanCountRate: '还款率-单数',
    huanKuanAmountRate: '还款率-金额',
    shouRiCount: '首日完成-单数',
    zhiNaJinAmount: '滞纳金总金额',
    zhiNaJinPayAmount: '滞纳金收取金额',
    zhiNaJinPayRate: '滞纳金回收率',
    cuiJiOrderCount: '催记单量',
    cuiJiOverRate: '催记完成率',
    cuiJiCount: '催记次数',
    cuiJiCountPreOrder: '平均每单催记数',
    anDate: '按日期',
    total: '累计',
    orderCount: '在手单量',
    yiHuanCount: '还款单数',
    yiHuanRate: '还款率'
  },
  en: {
    billDate: 'Bill date',
    dataCaliber: 'Data caliber',
    hierarchyObject: 'Hierarchy object',
    departmentName: 'Department Name',
    collectionStage: 'Level',
    groupName: 'Team',
    collectorName: 'Name',
    date: 'Date',
    department: 'Department',
    orderNum: ' Orders',
    repaidOrders: 'Repaid orders(incl extensions) ',
    orderAmount: 'Due Date Amount-Total',
    yiHuanAmount: 'Due date amount-repaid',
    huanKuanCountRate: 'Repayment rate-order',
    huanKuanAmountRate: 'Repayment rate-amount',
    shouRiCount: 'First day success',
    zhiNaJinAmount: 'Total amount of late fee',
    zhiNaJinPayAmount: 'Recovery late fee',
    zhiNaJinPayRate: 'Recovery rate of late fees',
    cuiJiOrderCount: 'Recorded order',
    cuiJiOverRate: ' Record completion rate',
    cuiJiCount: 'Record times',
    cuiJiCountPreOrder: 'Average times per order',
    anDate: 'Date',
    total: 'total',
    orderCount: 'hand orders',
    yiHuanCount: 'returned orders',
    yiHuanRate: 'returned rate'
  }
}
