export default {
    zh: {
        remindTaskStatus: '提醒任务状态',
        remindTaskDetail: '提醒任务详情',
        orderNo: '申请编号',
        createTime: '入案时间',
        endDate: '到期时间',
        remindRecord: '提醒记录',
        userName: '员工姓名',
        remindNumber: '案件数量',
        settleNumber: '结清数量',
        diferNumber: '展期数量',
        overdueNumber: '逾期数量',
        dealWith: '处理',
        endTime:'到期时间',
    },
    en: {
        remindTaskStatus: 'Remind Task Status',
        remindTaskDetail: 'Remind Task Detail',
        orderNo: 'Order Number',
        createTime: 'Create Time',
        endDate: 'End Date',
        remindRecord: 'Remind Record',
        userName: 'User Name',
        remindNumber: 'Remind Number',
        settleNumber: 'Settle Number',
        diferNumber: 'Difer Number',
        overdueNumber: 'Overdue Number',
        dealWith: 'Deal With',
        endTime: 'End Date',
    }
}