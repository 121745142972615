import { axios } from '@/utils/request'
import dictCode from '../constants/dict-code';
const codes = Object.values(dictCode).join(',');

export function getDictList (parameter = {}) {
  return axios({
    url: `/dict/findDictItemByCode/${codes}/anon`,
    method: 'post',
    data: parameter
  })
}
