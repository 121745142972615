import lang from '../../i18n/lang/'

const types = {
    SET_LANGUAGE: 'SET_LANGUAGE',
    DELETE_LANGUAGE: 'DELETE_LANGUAGE',
};
const state = {
    languageStorage: localStorage.getItem('language') || lang.default,
};
const getters = {
    languageStorage (state) {
        return state.languageStorage;
    },
};

const actions = {
    setLanguage ({ commit }, data) {
        commit(types.SET_LANGUAGE, data)
    },
    deleteLanguage ({ commit }) {
        commit(types.DELETE_LANGUAGE)
    }
};

const mutations = {
    [types.SET_LANGUAGE] (state, data) {
        localStorage.setItem('language', data);
        state.languageStorage = data;
    },
    [types.DELETE_LANGUAGE] (state, data) {
        localStorage.removeItem('language');
        state.languageStorage = lang.default;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
