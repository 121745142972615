<template>
  <div>
    <div id="container"></div>
  </div>
</template>
<script>
import G2 from '@antv/g2';
const path = 'M381.759 0h292l-.64 295.328-100.127-100.096-94.368 94.368C499.808 326.848 512 369.824 512 415.712c0 141.376-114.56 256-256 256-141.376 0-256-114.624-256-256s114.624-256 256-256c48.8 0 94.272 13.92 133.12 37.632l93.376-94.592L381.76 0zM128.032 415.744c0 70.688 57.312 128 128 128s128-57.312 128-128-57.312-128-128-128-128 57.312-128 128z'

const scale = [{
    dataKey: 'value',
    min: 0,
    max: 100,
}];
export default {
    props: {
        height: {
            type: Number,
            default: 0
        },
        width: {
            type: Number,
            default: 0
        },
        title: {
            type: String,
            default: ''
        },
        data: {
            type: Array,
            default: () => {
                return []
            }
        },
    },
    data () {
        return {
            scale,
        };
    },
    watch: {
      data (val) {
        val && this.drawChart(val);
      }
    },
    mounted () {
      this.drawChart(this.data);
    },
    methods: {
        drawChart(data) {
            const { height } = this;
            this.chart && this.chart.destroy();
            this.chart = new G2.Chart({
                container: this.$el,
                forceFit: true,
                height: height,
                scale,
                padding: 'auto',
            });
            this.chart.legend(false);
            this.chart.axis(false);
            this.chart.interval()
                .position('transfer*value')
                .color('transfer')
                .shape('path', path => [ 'liquid-fill-path', path ])
                .shape('liquid-fill-gauge')
                .style({
                    lineWidth: 10,
                    opacity: 0.75
                })
                .tooltip('transfer*value', (transfer, value) => {
                    return {
                      name: transfer,
                      value: value + '%'
                    };
                })

            this.chart.tooltip({ showTitle: false, title: this.title })
            this.chart.source(data, {
                value: {
                  min: 0,
                  max: 100
                }
            });
            this.data.forEach(row => {
                this.chart.guide().text({
                  top: true,
                  position: {
                    transfer: row.transfer,
                    value: 50
                  },
                  content: `${row.value}%`,
                  style: {
                    opacity: 0.75,
                    fontSize: 30,
                    textAlign: 'center'
                  }
                });
            });

            this.chart.render();
        }
    }
};
</script>


